<template>
  <div>
    <b-card header-tag="header" style="min-height: 750px;">
      <template v-if="p_isModal">
        <b-card header-tag="header" footer-tag="footer" no-body>
          <b-card bg-variant="primary" style="margin-bottom: 0px; background-color: #3c9ca5!important;" no-body>
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <b-row>
                <b-col cols="11">
                  <img v-if="patient.sex === '0'" src="../../../static/img/avatars/man.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" />
                  <img v-else src="../../../static/img/avatars/woman.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" /> {{ w_presentation ? 'HASTA AD SOYAD' : patient.firstname.toLocaleUpperCase('tr') + ' ' + patient.lastname.toLocaleUpperCase('tr') }}, {{ w_presentation ? 'YAŞ' : patient.age + ' yaş' }}
                </b-col>
                <b-col cols="1">
                  <b-button class="pull-right" size="sm" variant="primary" @click="d_showPatientInfoMode ? d_showPatientInfoMode = false : d_showPatientInfoMode = true"><i class="fa fa-edit"></i></b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <template v-if="d_showPatientInfoMode">
              <b-row style="padding: 3px;">
                <b-col sm="12" md="6">
                  <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      <i class="fa fa-database"></i> Hastane Kayıt Bilgileri
                      <span class="pull-right" style="margin-right: 30px;">
                  </span>
                    </b-card-header>
                    <b-row>
                      <b-col cols="12">
                        <div id="s_operationsScrollbar" style="min-height: 120px; max-height: 150px; overflow-y: scroll; overflow-x: hidden; border-radius: 10px 0 0 10px; padding: 3px">
                          <template v-if="!patient.hospitals || (patient.hospitals && Object.keys(patient.hospitals).length === 0)">
                            {{ w_presentation ? 'SUNUM' : 'Hastane bağlantısı henüz tanımlanmamıştır.' }}
                          </template>
                          <template v-else>
                            <template v-for="(hos_grp, hos_grp_ind) in patient.hospitals">
                              <b-row v-for="(hos, hos_ind) in hos_grp" :key="'hos_grp_' + hos_ind" class="text-primary">
                                <b-col sm="12" lg="1"><i class="fa fa-connectdevelop bg-primary p-1"></i></b-col>
                                <b-col sm="12" lg="11">
                                  {{ w_presentation ? 'SUNUM' : hos_grp_ind + ' / ' + hos_ind }}
                                  <span v-if="hos.webservice === 1"> {{ w_presentation ? '/ SUNUM' : '/ WebServis Aktif' }}</span>
                                  <span v-else> {{ w_presentation ? '/ SUNUM' : '/ WebServis Pasif' }}</span> &nbsp&nbsp&nbsp
                                  <b-button size="sm" v-if="!w_presentation && hos.data && hos.data.id" @click="f_showPatientHospitalDataModal(hos.data)"><i class="fa fa-caret-right"></i>detay</b-button>
                                </b-col>
                              </b-row>
                            </template>
                          </template>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card>
      </template>
      <b-row v-if="!show_new_wisdom_data">
        <b-col cols="12">
          <b-card bg-variant="primary" style="margin-bottom: 0px; background-color: #3c9ca5!important;" no-body>
            <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
              <b-row v-if="d_showScreenSettings" style="text-align: center; border-bottom: solid 1px red; margin: 0px; margin-bottom: 5px;">
                <b-col cols="12">
                  <strong style="color: red; font-size: 20px;">! EKRAN AYARLARI MODU AKTİF</strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="!d_showScreenSettings" sm="12" md="2" :lg="d_showMode ? '2' : '1' ">
                  <b-row>
                    <b-col sm="12" md="12" lg="12">
                      <template v-if="!d_showScreenSettings">
                        <!-- <i class="fa fa-file-text-o"></i> Hasta Takip <small>(Veri Filtreleme Alanı)</small> -->
                        <b-dropdown v-if="!d_showScreenSettings && !p_organizationTask" variant="secondary" size="md" style="margin-right: 5px;" :title="d_selectedScreenModeIndex === '' ? 'ekran modu seçili değil' : d_userScreenModes[d_selectedScreenModeIndex].label">
                          <template v-slot:button-content>
                            <span style="font-size: 10px;">
                              {{ d_selectedScreenModeIndex === '' ? 'ekran' : d_userScreenModes[d_selectedScreenModeIndex].label.length > 10 ? d_userScreenModes[d_selectedScreenModeIndex].label.substring(0, 10) + '..' : d_userScreenModes[d_selectedScreenModeIndex].label }}
                            </span>
                          </template>
                          <b-dropdown-item v-for="(mode, mode_ind) in d_userScreenModes" :key="'d_userScreenModes_1_' + mode_ind" @click="f_selectTheScreenMode(mode_ind)">
                            <img v-if="mode.default" src="@/icon/937437.png" style="width: 2.3em;" />
                            <img v-else src="@/icon/2566224.png" style="width: 2.3em;" /> {{ mode.label }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="!d_showMode" sm="12" md="12" lg="5">
                  <template>
                    <b-row>
                      <b-col sm="12" md="5">
                        <template v-if="!d_drugMode">
                          <!-- <b-button v-if="d_timeLineMode" size="md" :variant="d_timeLineMode ? 'warning' : 'secondary'" :style="d_timeLineMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_timeLineMode('left_to_right')">
                            <img src="@/icon/3037139.png" title="Zaman Çizelgesi Modu Aktif" style="width: 2.5em;" />
                          </b-button> -->
                          <template v-if="!d_timeLineMode && !d_multiWdmrMode">
                            <b-button :variant="view_type_wisdom_data === 'long' ? 'warning' : 'secondary'" size="md" :style="view_type_wisdom_data === 'long' ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="TypeWisdomData()">
                              <img src="@/icon/629153.png" title="Veri Aralığı Genişliği" style="width: 2.5em;" />
                            </b-button>
                            <b-button size="md" variant="warning" style="margin-right: 5px; padding: 0px;" @click="f_changeViewMode()">
                              <template v-if="view_mode === 'table'">
                                <img src="@/icon/1715140.png" title="Tablo Modu" style="width: 2.5em;" />
                              </template>
                              <template v-else-if="view_mode === 'sentence'">
                                <img src="@/icon/3082142.png" title="Cümle Modu" style="width: 2.5em;" />
                              </template>
                              <template v-else-if="view_mode === 'polestar'">
                                <img src="@/icon/2333399.png" title="Polestar Modu" style="width: 2.5em;" />
                              </template>
                              <template v-else-if="view_mode === 'wdm_specific'">
                                <img src="@/icon/2956643.png" title="WDM Spesifik" style="width: 2.5em;" />
                              </template>
                            </b-button>
                            <b-button size="md" :variant="d_dateListMode === 'first_new' ? 'warning' : 'secondary'" :style="d_dateListMode === 'first_new' ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_dateListMode === 'first_old' ? d_dateListMode = 'first_new' : d_dateListMode = 'first_old'">
                              <img src="@/icon/1319596.png" title="En Güncel Tarih En Üstte" style="width: 2.5em;" />
                            </b-button>
                            <b-button size="md" :variant="brief_mode ? 'warning' : 'secondary'" :style="brief_mode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="brief_mode ? brief_mode = 0 : brief_mode = 1; f_wisdomUpdate()">
                              <img src="@/icon/236883.png" title="Özet Modu" style="width: 2.5em;" />
                            </b-button>
                          </template>
                        </template>
                      </b-col>
                      <b-col sm="12" md="7">
                        <template v-if="!d_drugMode && !d_timeLineMode && !d_multiWdmrMode">
                          <b-row>
                            <b-col v-if="d_showScreenSettings" sm="12" md="3">
                              <b-dropdown variant="secondary" size="md" style="margin-right: 3px;">
                                <template v-slot:button-content>
                                  <img src="@/icon/2940638.png" title="Hızlı Veri Modeli" style="width: 1.7em;" />
                                </template>
                                <b-dropdown-item v-for="(model, model_ind) in wdm.ful.patient_follow" :key="'wdm.ful.patient_follow_1_' + model_ind" v-if="f_userHasPermissionToAddThisModel(model.value)" @click="f_addToFastWdmList(model)"> <i class="fa fa-plus"></i> {{ model.label }} </b-dropdown-item>
                              </b-dropdown>
                            </b-col>
                            <b-col sm="12" :md="d_showScreenSettings ? 9 : 12">
                              <template v-for="(model, model_ind) in d_fastWdmIconList">
                                <b-button size="md" class="pull-left" v-if="(!d_showScreenSettings && model_ind < 3) || d_showScreenSettings" :style="d_wdmDetails[model.value] && d_wdmDetails[model.value].color ? 'margin-right: 3px; width: 125px; background-color: ' + d_wdmDetails[model.value].color : 'margin-right: 3px; width: 125px;'" @click="add_new_wisdom_data(model.value, 'fast_mode')">
                                  <i class="fa fa-plus"></i> {{ f_showThisChars(model.label, 5) }}
                                </b-button>
                                <template v-if="d_showScreenSettings">
                                  <b-button style="margin: 3px;" variant="danger" size="md" @click="d_fastWdmIconList.splice(model_ind, 1); $forceUpdate()"><i class="fa fa-trash"></i></b-button>
                                  <br>
                                </template>
                              </template>
                              <b-dropdown v-if="!d_showScreenSettings && d_fastWdmIconList.length > 3" variant="secondary" size="md" style="margin-right: 3px;">
                                <template v-slot:button-content>
                                  <img src="@/icon/2940638.png" title="Hızlı Veri Modeli" style="width: 1.7em;" />
                                </template>
                                <template v-for="(model, model_ind) in d_fastWdmIconList">
                                  <b-dropdown-item :key="'wdm.ful.patient_follow_2_' + model_ind" v-if="f_userHasPermissionToAddThisModel(model.value) && model_ind > 2" @click="add_new_wisdom_data(model.value, 'fast_mode')"> <i class="fa fa-plus"></i> {{ model.label }} </b-dropdown-item>
                                </template>
                              </b-dropdown>
                            </b-col>
                          </b-row>
                        </template>
                      </b-col>
                    </b-row>
                  </template>
                </b-col>
                <b-col sm="12" md="10" :lg="d_showMode ? '10' : '6' " :style="d_showMode ? 'text-align: right;' : 'text-align: right;'">
                  <b-button size="md" :variant="d_multiWdmrMode ? 'warning' : 'secondary'" :style="d_multiWdmrMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_multiWdmrMode()">
                    <img src="@/icon/2197761.png" title="Örnek Şablon Veri Setlerinden Ekleme Modu" style="width: 3em;" />
                  </b-button>
                  <b-button size="md" :variant="d_timeLineMode ? 'warning' : 'secondary'" :style="d_timeLineMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_timeLineMode('left_to_right')">
                    <img src="@/icon/3037139.png" title="Soldan Sağa Zaman Çizelgesi Modu" style="width: 3em;" />
                  </b-button>
                  <b-dropdown v-if="!p_organizationTask && !d_drugMode && !d_showScreenSettings && !d_multiWdmrMode" variant="secondary" size="md" right style="margin-right: 5px;">
                    <template v-slot:button-content>
                      <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2.2em;" title="Öne açılan ekranda eklenir" />
                    </template>
                    <b-dropdown-item v-for="(model, model_ind) in wdm.ful.patient_follow" :key="'wdm.ful.patient_follow_3_' + model_ind" v-if="f_userHasPermissionToAddThisModel(model.value)" @click="add_new_wisdom_data(model.value)"> <i class="fa fa-plus"></i> {{ model.label }} </b-dropdown-item>
                  </b-dropdown>
                  <b-button size="md" :variant="d_drugMode ? 'warning' : 'secondary'" :style="d_drugMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_changeDrugMode()">
                    <img src="@/icon/1863825.png" title="İlaç Tedavi Modu" style="width: 3em;" />
                  </b-button>
                  <template v-if="patient.hospitals && Object.keys(patient.hospitals).length > 0 && patient.hospitals[user.hospital_group] && patient.hospitals[user.hospital_group][user.hospital_id] && patient.hospitals[user.hospital_group][user.hospital_id].webservice === 1">
                    <b-dropdown right size="md" variant="secondary" style="margin-right: 5px; padding: 0px;">
                      <template v-slot:button-content>
                        <img src="@/icon/2704159.png" class="img-rounded img-responsive" style="width: 2.2em;" title="Hızlı HBYS - WebService İşlemi" />
                      </template>
                      <b-dropdown-item-button @click="f_showForceGetHimsData()" title="Bir hastaya bir defa giriş yapıldığında, o ana kadar olan hbys tarafında bulunan tüm muayene bilgileri WisdomEra tarafına aktarılmakta, bundan sonraki muayene bilgileri aktif kullanımı rahatsız etmesi sebebiyle otomatik olarak getirilmemektedir. Ancak, ihtiyaç dahilinde hbys tarafındaki mevcut muayene bilgilerini tekrar çekmek isterseniz, bu fonksiyonu çalıştırabilirsiniz."> <i class="fa fa-plus"></i> Muayene bilgilerini getir </b-dropdown-item-button>
                    </b-dropdown>
                  </template>
                  <!-- <b-button v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1 || account_list[0].special_permission.indexOf('administrator') !== -1)" variant="secondary" size="md" style="margin-right: 5px; padding: 0px;" @click="f_showLabOrRadOrderModal('laboratory')">
                    <img src="@/icon/1260379.png" title="Laboratuar İstemi" style="width: 3em; cursor: pointer;" />
                  </b-button> -->
                  <!-- <b-button v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1 || account_list[0].special_permission.indexOf('administrator') !== -1)" variant="secondary" size="md" style="margin-right: 5px; padding: 0px;" @click="f_showLabOrRadOrderModal('radiology')">
                    <img src="@/icon/641.png" title="Radyoloji İstemi" style="width: 3em; cursor: pointer;" />
                  </b-button> -->
                  <b-button v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1 || account_list[0].special_permission.indexOf('administrator') !== -1)" variant="secondary" size="md" style="margin-right: 5px; padding: 0px;" @click="f_showPatientReportInformationModal()">
                    <img src="@/icon/2976082.png" title="Rapor Listesi" style="width: 3em; cursor: pointer;" />
                  </b-button>
                  <b-button size="md" :variant="d_showScreenSettings ? 'warning' : 'secondary'" :style="d_showScreenSettings ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_showScreenSettings === true ? d_showScreenSettings = false : d_showScreenSettings = true;">
                    <img src="@/icon/2566224.png" title="Ekran Ayarları Modu" style="width: 3em; cursor: pointer;" />
                  </b-button>
                  <b-button size="md" :variant="d_showMode ? 'warning' : 'secondary'" :style="d_showMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_changeShowMode()">
                    <img src="@/icon/45774.png" title="Üst Alanı Aç Kapa" style="width: 3em;" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row v-if="d_showMode" style="padding: 3px; margin: 0px;">
              <b-col v-if="!p_organizationTask" sm="12" md="4" style="padding: 3px;">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-building-o"></i> Departman
                    <b-button class="pull-right" variant="warning" size="sm" @click="f_selectAllDepartments()"><i class="fa fa-check"></i></b-button>
                    <span class="pull-right" style="margin-right: 30px;">
                      <b-dropdown id="dropdown-form" text="departmanlar" ref="dropdown" class="p-1 pull-right" size="sm" right>
                        <b-dropdown-text style="width: 500px; padding: 0px;">
                          <tree-list :show_mode="tree_list.department.show_mode" :tree_list_type="'department'" :select_type="tree_list.department.select_type" :mode="tree_list.department.mode" :selected_items_data_list="tree_list.department.selected_items_data_list" :max_height="tree_list.department.max_height" :show_items_on_top="tree_list.department.show_items_on_top" :follow_up_location="'patient_follow'" :show_selected_item_parents="tree_list.department.show_selected_item_parents" :eligible_items_list="tree_list.department.eligible_items_list"></tree-list>
                        </b-dropdown-text>
                      </b-dropdown>
                    </span>
                  </b-card-header>
                  <b-row v-if="st_department && st_department.ful && st_department.ful.patient_follow">
                    <b-col cols="12">
                      <div id="s_operationsScrollbar" style="min-height: 120px; max-height: 150px; overflow-y: scroll; overflow-x: hidden; border-radius: 10px 0 0 10px; padding: 3px">
                        <template v-for="(dep, dep_ind) in f_sortWithSelectedUpper(st_department.ful.patient_follow, d_selectedDepartmentList)">
                          <b-row :class="f_calculateDepartmentClass(dep.value)" @click="f_addRemoveDepartment(dep.value)">
                            <b-col cols="12" style="padding: 1px;">
                              <i :class="[d_selectedDepartmentList.indexOf(dep.value) !== - 1 ? 'fa fa-check' : 'fa fa-caret-right']"></i> {{ dep.label }} <i v-if="d_patientVisitedDepartments.indexOf(dep.value) !== -1" class="fa fa-database"></i>
                            </b-col>
                          </b-row>
                        </template>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col sm="12" md="8" style="border-radius: 15px; padding: 3px;">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-database"></i> Veri Modelleri
                    <b-button class="pull-right" variant="warning" size="sm" @click="f_selectAllModels()"><i class="fa fa-check"></i></b-button>
                    <span class="pull-right" style="margin-right: 30px;">
                      <b-dropdown id="dropdown-form" text="modüller" ref="dropdown" class="p-1 pull-right" size="sm" right>
                        <b-dropdown-text style="width: 500px; padding: 0px;">
                          <tree-list :show_mode="tree_list.module_and_model.show_mode" :tree_list_type="'module_and_model'" :select_type="tree_list.module_and_model.select_type" :mode="tree_list.module_and_model.mode" :selected_items_data_list="tree_list.module_and_model.selected_items_data_list" :max_height="tree_list.module_and_model.max_height" :show_items_on_top="tree_list.module_and_model.show_items_on_top" :follow_up_location="'patient_follow'"></tree-list>
                        </b-dropdown-text>
                      </b-dropdown>
                    </span>
                  </b-card-header>
                  <b-row v-if="wdm && wdm.ful && wdm.ful.patient_follow && wdm.ful.patient_follow.length > 0" style="margin: 0px;">
                    <b-col cols="12" style="padding: 0px;">
                      <div id="s_operationsScrollbar" style="min-height: 120px; max-height: 150px; overflow-y: scroll; overflow-x: hidden; border-radius: 10px 0 0 10px; padding: 3px">
                        <template v-for="(model, model_ind) in f_sortWithSelectedUpper(wdm.ful.patient_follow, d_selectedWdmList)">
                          <b-row v-if="!d_drugMode || (d_drugMode && model.value === 'wdm10')" :class="f_calculateWdmClass(model.value)">
                            <b-col cols="6" @click="f_addRemoveWdm(model)" style="padding: 1px;">
                              <i :class="[d_selectedWdmList.indexOf(model.value) !== - 1 ? 'fa fa-check' : 'fa fa-caret-right']"></i> {{ model.label }}
                            </b-col>
                            <template v-if="d_selectedWdmList.indexOf(model.value) !== - 1 && d_searchData.show_last_x_data_list[model.value]">
                              <b-col cols="5">
                                <select v-model="d_searchData.show_last_x_data_list[model.value]['val']" required style="width: 100%; border-radius: 3px 3px;" @keyup="$forceUpdate()">
                                  <option value="all">Tüm kayıtlı veriler</option>
                                  <option v-for="x in 10" :value="x">Kayıtlı son {{ x }} veri</option>
                                  <option value="date_interval">Belirlenen tarih aralığı</option>
                                </select>
                              </b-col>
                              <b-col v-if="d_searchData.show_last_x_data_list[model.value].val === 'date_interval'" cols="1" :style="model_ind%2 === 0 ? 'padding: 1px; background-color: #1cba1c;' : 'padding: 1px; background-color: #5eddb2;'" @click="f_selectModelForDateInterval(model.value)" title="Tarih Aralığı Seçmek İçin Lütfen Tıklayınız.">
                                <i class="fa fa-edit"></i>
                              </b-col>
                              <b-col v-else cols="1"></b-col>
                            </template>
                            <template v-else>
                              <b-col cols="5" style="padding: 1px;" @click="f_addRemoveWdm(model)"></b-col>
                              <b-col cols="1" style="padding: 1px;"> </b-col>
                            </template>
                          </b-row>
                          <template v-if="d_selectedModelsForDateInterval.indexOf(model.value) !== -1 && d_searchData.show_last_x_data_list[model.value] && d_searchData.show_last_x_data_list[model.value].val === 'date_interval'">
                            <b-row>
                              <b-col cols="6">
                                <strong>başlama:</strong>
                              </b-col>
                              <b-col cols="6">
                                <b-form-input type="date" id="d_startingAt" v-model="d_searchData.show_last_x_data_list[model.value].started_at"></b-form-input>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols="6">
                                <strong>bitiş:</strong>
                              </b-col>
                              <b-col cols="6">
                                <b-form-input type="date" id="d_startingAt" v-model="d_searchData.show_last_x_data_list[model.value].ending_at"></b-form-input>
                              </b-col>
                            </b-row>
                          </template>
                        </template>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-card-footer v-if="d_showMode" footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;">
              <b-row>
                <b-col sm="12" md="4">
                  <template v-if="!d_drugMode">
                    <b-button v-if="d_timeLineMode" size="md" :variant="d_timeLineMode ? 'warning' : 'secondary'" :style="d_timeLineMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_timeLineMode ? d_timeLineMode = 0 : d_timeLineMode = 1; f_getPatientRecords()">
                      <img src="@/icon/3037139.png" title="Zaman Çizelgesi Modu Aktif" style="width: 2.5em;" />
                    </b-button>
                    <template v-if="!d_timeLineMode">
                      <b-button :variant="view_type_wisdom_data === 'long' ? 'warning' : 'secondary'" size="md" :style="view_type_wisdom_data === 'long' ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="TypeWisdomData()">
                        <img src="@/icon/629153.png" title="Veri Aralığı Genişliği" style="width: 2.5em;" />
                      </b-button>
                      <b-button size="md" variant="warning" style="margin-right: 5px; padding: 0px;" @click="f_changeViewMode()">
                        <template v-if="view_mode === 'table'">
                          <img src="@/icon/1715140.png" title="Tablo Modu" style="width: 2.5em;" />
                        </template>
                        <template v-else-if="view_mode === 'sentence'">
                          <img src="@/icon/3082142.png" title="Cümle Modu" style="width: 2.5em;" />
                        </template>
                        <template v-else-if="view_mode === 'polestar'">
                          <img src="@/icon/2333399.png" title="Polestar Modu" style="width: 2.5em;" />
                        </template>
                        <template v-else-if="view_mode === 'wdm_specific'">
                          <img src="@/icon/2956643.png" title="WDM Spesifik" style="width: 2.5em;" />
                        </template>
                      </b-button>
                      <b-button size="md" :variant="d_dateListMode === 'first_new' ? 'warning' : 'secondary'" :style="d_dateListMode === 'first_new' ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_dateListMode === 'first_old' ? d_dateListMode = 'first_new' : d_dateListMode = 'first_old'">
                        <img src="@/icon/1319596.png" title="En Güncel Tarih En Üstte" style="width: 2.5em;" />
                      </b-button>
                      <b-button size="md" :variant="brief_mode ? 'warning' : 'secondary'" :style="brief_mode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="brief_mode ? brief_mode = 0 : brief_mode = 1; f_wisdomUpdate()">
                        <img src="@/icon/236883.png" title="Özet Modu" style="width: 2.5em;" />
                      </b-button>
                    </template>
                  </template>
                </b-col>
                <b-col sm="12" md="4">
                  <template v-if="!d_drugMode && !d_timeLineMode">
                    <b-row>
                      <b-col v-if="d_showScreenSettings" sm="12" md="3">
                        <b-dropdown variant="secondary" size="md" style="margin-right: 5px;">
                          <template v-slot:button-content>
                            <img src="@/icon/2940638.png" title="Hızlı Veri Modeli" style="width: 1.7em;" />
                          </template>
                          <b-dropdown-item v-for="(model, model_ind) in wdm.ful.patient_follow" :key="'wdm.ful.patient_follow_4_' + model_ind" v-if="f_userHasPermissionToAddThisModel(model.value)" @click="f_addToFastWdmList(model)"> <i class="fa fa-plus"></i> {{ model.label }} </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                      <b-col sm="12" :md="d_showScreenSettings ? 9 : 12">
                        <template v-for="(model, model_ind) in d_fastWdmIconList">
                          <b-button size="md" class="pull-left" v-if="(!d_showScreenSettings && model_ind < 3) || d_showScreenSettings" :style="d_wdmDetails[model.value] && d_wdmDetails[model.value].color ? 'margin-right: 3px; width: 125px; background-color: ' + d_wdmDetails[model.value].color : 'margin-right: 3px; width: 125px;'" @click="add_new_wisdom_data(model.value, 'fast_mode')">
                            <i class="fa fa-plus"></i> {{ f_showThisChars(model.label, 5) }}
                          </b-button>
                          <template v-if="d_showScreenSettings">
                            <b-button style="margin: 3px;" variant="danger" size="md" @click="d_fastWdmIconList.splice(model_ind, 1); $forceUpdate()"><i class="fa fa-trash"></i></b-button>
                            <br>
                          </template>
                        </template>
                        <b-dropdown v-if="!d_showScreenSettings && d_fastWdmIconList.length > 3" variant="secondary" size="md" style="margin-right: 3px;">
                          <template v-slot:button-content>
                            <img src="@/icon/2940638.png" title="Hızlı Veri Modeli" style="width: 1.7em;" />
                          </template>
                          <template v-for="(model, model_ind) in d_fastWdmIconList">
                            <b-dropdown-item :key="'wdm.ful.patient_follow_2_' + model_ind" v-if="f_userHasPermissionToAddThisModel(model.value) && model_ind > 2" @click="add_new_wisdom_data(model.value, 'fast_mode')"> <i class="fa fa-plus"></i> {{ model.label }} </b-dropdown-item>
                          </template>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </template>
                </b-col>
                <b-col sm="12" md="4">
                  <b-button v-if="!d_showScreenSettings" variant="warning" size="md" style="width: 100%;" @click="f_getPatientRecords()"><i class="fa fa-cloud-download"></i> verileri getir</b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
          <!-- <b-card v-if="d_showMode" no-body class="mb-1" header-tag="header" key="patient-diagnosis-list">
            <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
              Hastanın ekli tanıları
            </b-card-header>
            <b-row>
              <b-col cols="12">
                <template v-if="patient.diagnosis && patient.diagnosis.length > 0">
                  <div v-for="(diag, diag_ind) in patient.diagnosis" :key="diag_ind + '-patient-diagnosis-' + diag.value" style="padding: 5px;"> <strong>{{ diag_ind + 1 }} )</strong> {{ diag.label }}</div>
                </template>
                <template v-else>
                  <strong style="color: red;">Hastanın tanı kaydı bulunmamaktadır. Lütfen HBYS üzerinden otomatik aktarım yapılabilmesi için tanı ekleyiniz.</strong>
                </template>
              </b-col>
            </b-row>
          </b-card> -->
          <b-card v-if="d_drugMode" no-body class="mb-1" header-tag="header">
            <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
              Tedavi Yönetimi
              <b-button @click="f_showDrugModeTimeLine()">f_showDrugModeTimeLine</b-button>
              <b-button style="margin-right: 5px; padding: 0px;" class="pull-right" size="sm" variant="warning" v-if="!d_showScreenSettings && d_drugMode && f_userHasPermissionToAddThisModel('wdm10')" @click="add_new_wisdom_data('wdm10')">
                <img src="@/icon/2766751.png" title="Yeni İlaç Planlaması Ekle" style="width: 3em;" />
              </b-button>
              <b-button v-if="!d_showScreenSettings && d_drugMode" class="pull-right" size="sm" :variant="d_wdmr10ShowMode ? 'warning' : 'secondary'" :style="d_wdmr10ShowMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_changeWdmr10ShowMode()">
                <img src="@/icon/42729.png" title="İlaç Planlamalarını Göster" style="width: 3em;" />
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" class="pull-right" size="sm" variant="warning" v-if="!d_showScreenSettings && d_drugMode && !d_wdmr10ShowMode" @click="d_drugModeDepartmentFilterType === 'all' ? d_drugModeDepartmentFilterType = 'selected' : d_drugModeDepartmentFilterType = 'all'">
                <img v-if="d_drugModeDepartmentFilterType === 'all'" src="@/icon/3233064.png" title="Tüm Departmanlara Ait Tedaviler" style="width: 3em;" />
                <img v-else src="@/icon/2223122.png" title="Departmanıma Ait Tedaviler" style="width: 3em;" />
              </b-button>
            </b-card-header>
            <b-row>
              <b-col sm="12" md="12">
                <bmi-gfr-widget :patient_data="patient" :bmi_gfr_change="bmi_gfr_change"></bmi-gfr-widget>
              </b-col>
            </b-row>
            <template v-if="d_wdmr10ShowMode">
              <b-row>
                <b-col cols="12">
                  <patient-drug :p_componentType="'show_wdmr10'" :p_wdmr10ShowMode="d_wdmr10ShowMode" :p_optionData="d_optionData" :p_showWdmr11or12="d_showWdmr11or12" :p_wdmr11or12List="d_wdmr11or12List" :mother_children="mother_children" :p_wdmr10List="patient_data_list ? patient_data_list : []" :p_patientDrugEditData="d_patientDrugEditData" :p_selectedWdmr10="d_selectedWdmr10"></patient-drug>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row v-if="d_wdmr11or12List.intermittently_followup.length > 0">
                <b-col cols="12">
                  <patient-drug :p_componentType="'intermittently_followup'" :p_wdmr10ShowMode="d_wdmr10ShowMode" :p_optionData="d_optionData" :p_showWdmr11or12="d_showWdmr11or12" :p_wdmr11or12List="d_wdmr11or12List" :mother_children="mother_children" :p_wdmr10List="patient_data_list ? patient_data_list : []" :p_patientDrugEditData="d_patientDrugEditData" :p_selectedWdmr10="d_selectedWdmr10"></patient-drug>
                </b-col>
              </b-row>
              <b-row v-if="d_wdmr11or12List.cycle_followup.length > 0">
                <b-col cols="12">
                  <patient-drug :p_componentType="'cycle_followup'" :p_wdmr10ShowMode="d_wdmr10ShowMode" :p_optionData="d_optionData" :p_showWdmr11or12="d_showWdmr11or12" :p_wdmr11or12List="d_wdmr11or12List" :mother_children="mother_children" :p_wdmr10List="patient_data_list ? patient_data_list : []" :p_patientDrugEditData="d_patientDrugEditData" :p_selectedWdmr10="d_selectedWdmr10"></patient-drug>
                </b-col>
              </b-row>
              <b-row v-if="d_wdmr11or12List.daily_followup.length > 0">
                <b-col cols="12">
                  <patient-drug :p_componentType="'daily_followup'" :p_wdmr10ShowMode="d_wdmr10ShowMode" :p_optionData="d_optionData" :p_showWdmr11or12="d_showWdmr11or12" :p_wdmr11or12List="d_wdmr11or12List" :mother_children="mother_children" :p_wdmr10List="patient_data_list ? patient_data_list : []" :p_patientDrugEditData="d_patientDrugEditData" :p_selectedWdmr10="d_selectedWdmr10"></patient-drug>
                </b-col>
              </b-row>
            </template>
          </b-card>
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="$store.commit('help_no', '149')">?</b-badge>
          <time-line v-if="!d_showScreenSettings && d_timeLineMode && d_timeLineLeftToRight.length > 0" :timeline_data="d_timeLineLeftToRight" :year_month_mode="d_timeLineYearMonthMode" :mother_children="mother_children"></time-line>
          <b-card v-if="!d_multiWdmrMode && d_fastMode.data_type && d_showFastWdmrAddMode && !d_timeLineMode" no-body class="mb-1" header-tag="header">
            <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
              <b-button size="md" title="Verileri Kaydet" variant="secondary" @click="f_saveWdm('fast_mode')" style="padding: 1px; margin-right: 5px;" class="pull-right">
                <img src="@/icon/2087726.png" style="width: 2em;" /> Kaydet
              </b-button>
            </b-card-header>
            <b-row style="height: 300px; overflow-y: auto;">
              <b-col cols="12">
                <wisdom-data-modal :data="d_fastMode.wisdom_data" :data_type="d_fastMode.data_type" :option_data="d_fastMode.option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-if="d_showScreenSettings" no-body>
            <b-button size="md" title="Ekran açılırken verileri getirmek istiyorsanız aktif yapın" :variant="d_isActiveBringingWdmrListWhileMounting ? 'warning' : 'secondary'" @click="d_isActiveBringingWdmrListWhileMounting ? d_isActiveBringingWdmrListWhileMounting = 0 : d_isActiveBringingWdmrListWhileMounting = 1" :style="d_isActiveBringingWdmrListWhileMounting ? 'border: solid 2px green; padding: 1px; margin-right: 5px;' : 'padding: 1px; margin-right: 5px;'">
              Açılırken verileri getir
            </b-button>
          </b-card>
          <b-card v-if="d_showScreenSettings" class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-asterisk"></i> Ekran Ayarları Modu
              <b-button size="md" title="Ekran Modlarını Kaydet" variant="secondary" @click="f_saveScreenModeList()" style="padding: 1px; margin-right: 5px;" class="pull-right">
                <img src="@/icon/2087726.png" style="width: 2em;" />
              </b-button>
            </b-card-header>
            <b-row style="border-bottom: solid 1px black; margin: 0px;">
              <b-col cols="3">
                <img src="@/icon/2063326.png" style="width: 2.5em;" /> <strong>Seçili Modu İsimlendir</strong>
              </b-col>
              <b-col cols="7">
                <b-form-input v-model="d_newScreenFormatName" placeholder="Lütfen ekranın mevcut görünüm modunu kendinize özel olarak isimlendiriniz."></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-button v-if="d_newScreenFormatName" size="md" title="Seçili Ekran Modunu Diğer Modlarınıza Ekler" variant="secondary" @click="f_addEditThisModeToUserScreenModes('new')" style="padding: 1px;">
                  <img src="@/icon/109638.png" style="width: 2em;" /> Yeni Modu Ekle
                </b-button>
              </b-col>
            </b-row>
            <template v-for="(mode, mode_ind) in d_userScreenModes">
              <b-row :key="'d_userScreenModes_' + mode_ind" :style="d_selectedScreenModeIndex === mode_ind ? 'border-bottom: solid 1px black; margin: 0px; background-color: #a9e7dd;' : 'border-bottom: solid 1px black; margin: 0px;'">
                <b-col cols="2">
                  <b-button size="md" title="Bu Ekran Modunu Girişte Getir" variant="secondary" @click="f_makeScreenModeDefault(mode_ind)" :style="mode.default ? 'border: solid 2px green; padding: 1px; margin-right: 5px;' : 'padding: 1px; margin-right: 5px;'">
                    <img v-if="mode.default" src="@/icon/937437.png" style="width: 2.5em;" />
                    <img v-else src="@/icon/2566224.png" style="width: 2.5em;" />
                  </b-button>
                  <b-button v-if="mode.d_timeLineMode" size="md" title="Seçili Aktif TimeLine Modu (TIMELINE_1) İlgili Alanda Çağrılınca gelir." variant="secondary" @click="f_makeScreenTimeLineModeRelation(mode_ind, 'timeline_1')" :style="mode.timeline_1 ? 'border: solid 2px green; padding: 1px; margin-right: 5px;' : 'padding: 1px; margin-right: 5px;'">
                    <img v-if="mode.timeline_1" src="@/icon/3163497.png" style="width: 2.5em;" />
                    <img v-else src="@/icon/3163497.png" style="width: 2.5em;" />
                  </b-button>
                </b-col>
                <b-col cols="7" @click="f_selectTheScreenMode(mode_ind)" style="cursor: pointer;">
                  <strong>{{ mode.label }}</strong>
                </b-col>
                <b-col cols="3">
                  <b-button size="md" title="Seçili Ekran Modunu Günceller" variant="secondary" @click="f_addEditThisModeToUserScreenModes('edit', mode_ind)" style="padding: 1px; margin-right: 5px;" class="pull-right">
                    <img src="@/icon/2063326.png" style="width: 2.5em;" />
                    <img src="@/icon/109638.png" style="width: 2.5em;" />
                  </b-button>
                  <b-button size="md" title="Seçili Ekran Modunu Siler" variant="secondary" @click="f_deleteScreenMode(mode_ind)" style="padding: 1px; margin-right: 5px;" class="pull-right">
                    <img src="@/icon/2960590.png" style="width: 2.5em;" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
          <div v-if="d_multiWdmrMode">
            <multi-wdmr :p_eligibleWdmList="wdm.ful.patient_follow" :patient="patient" :p_screenModeWdmrSettings="d_screenModeWdmrSettings" :p_himsProtocolList="hims_protocol_list"></multi-wdmr>
          </div>
          <b-card v-if="d_showScreenSettings" class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px; min-height: 500px;">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              Özet Parametreleri Düzenleme
              <b-button size="md" title="Özet Parametreleri Kaydet" variant="secondary" @click="f_saveBriefParameters()" style="padding: 1px; margin-right: 5px;" class="pull-right">
                <img src="@/icon/2087726.png" style="width: 2em;" />
              </b-button>
            </b-card-header>
            <b-row>
              <b-col cols="6">
                Veri Modeli Seçimi
              </b-col>
              <b-col cols="6">
                <v-select :options="d_wdmParameters.user_selected_brief_wdm_list" v-model="d_wdmParameters.selected_wdm"></v-select>
              </b-col>
            </b-row>
            <template v-if="d_wdmParameters.data_type && d_wdmParameters.option_data && d_wdmParameters.option_data[d_wdmParameters.data_type]">
              <b-row style="border-bottom: solid 1px green; margin: 0px;">
                <b-col sm="12" lg="6">
                  <strong style="color: green;">{{ d_wdmParameters.selected_wdm.label }}</strong>
                  <small> {{ $t('wdm16.12786') }}</small>
                </b-col>
                <b-col sm="12" lg="6">
                  <b-form-input v-model="d_wdmParameters.search_in_first_parameter_list" :placeholder="$t('wdm16.12787')"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <wdm-parameters :data_type="d_wdmParameters.data_type" :search_in_first_parameter_list="d_wdmParameters.search_in_first_parameter_list" :option_data="d_wdmParameters.option_data" :selected_index_data="d_wdmParameters.selected_index_data" :show_parameter_features="d_wdmParameters.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParameters.param_change" :brief_list="d_wdmParameters.brief_list[d_wdmParameters.selected_wdm.value]"></wdm-parameters>
                </b-col>
                <b-col cols="6">
                </b-col>
              </b-row>
            </template>
          </b-card>
          <b-row v-if="!d_showScreenSettings && d_loadingSentence.f_getPatientRecords.show" style="text-align: center;">
            <b-col cols="12">
              <b-card-header header-bg-variant="warning" class="p-1">
                <strong style="color: black;">{{ d_loadingSentence.f_getPatientRecords.text }}</strong>
              </b-card-header>
            </b-col>
          </b-row>
          <template v-if="d_showPatientDataRecords && !d_drugMode && !d_timeLineMode && !d_multiWdmrMode">
            <template v-if="patient_data_list.length === 0">
              <b-row style="text-align: center;">
                <b-col cols="12">
                  <b-card-header header-bg-variant="warning" class="p-1">
                    <strong style="color: black;"> Hastaya Ait Görüntülenecek Veri Bulunamadı </strong>
                  </b-card-header>
                </b-col>
              </b-row>
            </template>
            <template v-else v-for="(x, x_ind) in d_dateListMode === 'first_new' ? reverse_patient_data_list : patient_data_list">
              <b-card v-if="x.reference_wdm.key && patient_wdm_list[x.reference_wdm.key]" no-body class="mb-1" header-tag="header" :key="x_ind">
                <b-card-header header-bg-variant="secondary" class="p-1">
                  <b-row>
                    <b-col cols="1" style="margin: 0px; padding-right: 0px;">
                      <b-dropdown left variant="primary" v-if="!p_organizationTask && !x.automatically_created">
                        <b-dropdown-item v-if="f_userHasPermissionToEditThisModel(x.department, x.type, x.hospital_group, x.hospital_id) && f_calculateWdm10And11And12Delete(x) && f_checkWdmrWebServiceEditable(x)" @click="f_deleteWdmr(x.id, x.type, x_ind)">
                          <i class="fa fa-trash"></i> Sil
                        </b-dropdown-item>
                        <b-dropdown-item v-if="f_userHasPermissionToEditThisModel(x.department, x.type, x.hospital_group, x.hospital_id) && f_calculateWdm10And11And12Edit(x) && f_checkWdmrWebServiceEditable(x)" @click="f_editWdmr(x_ind, x.type)">
                          <i class="fa fa-edit"></i> Düzenle
                        </b-dropdown-item>
                        <b-dropdown-item v-if="f_userHasPermissionToAddThisModel(x.type) && f_calculateWdm10And11And12Edit(x) && f_checkWdmrWebServiceEditable(x)" @click="f_cloneWdmr(x_ind, x.type)">
                          <i class="fa fa-clone"></i> Kopyala
                        </b-dropdown-item>
                        <b-dropdown-item @click="f_showWdmrReport(x_ind)">
                          <i class="fa fa-edit"></i> Raporla
                        </b-dropdown-item>
                        <!-- <b-dropdown-item v-if="x.type === 'wdm11' && f_calculateWdm11ApproveLimit(x) && f_userHasPermissionToEditThisModel(x.department, x.type)" @click="f_approveWdm11(x, 'doctor', 'doctor_3')">
                          <i class="fa fa-edit"></i> Tedavi başka bir merkezde uygulanmış
                        </b-dropdown-item>
                        <b-dropdown-item v-if="x.type === 'wdm11' && f_calculateWdm11ApproveLimit(x) && f_userHasPermissionToEditThisModel(x.department, x.type)" @click="f_approveWdm11(x, 'doctor', 'doctor_4')">
                          <i class="fa fa-edit"></i> Hasta ilacı evde aldığını ifade etti
                        </b-dropdown-item>
                        <b-dropdown-item v-if="x.type === 'wdm11' && f_calculateWdm11ApproveLimit(x) && f_userHasPermissionToEditThisModel(x.department, x.type)" @click="f_approveWdm11(x, 'doctor', 'doctor_7')">
                          <i class="fa fa-edit"></i> Hasta bu tedaviyi daha önceden aldı
                        </b-dropdown-item>
                        <b-dropdown-item v-if="x.type === 'wdm11' && f_calculateWdm11ContinueLimit(x) && f_userHasPermissionToEditThisModel(x.department, x.type)" @click="f_continueWdm11(x)">
                          <i class="fa fa-edit"></i> Tedaviyi bugünden itibaren devam ettir
                        </b-dropdown-item>
                        <b-dropdown-item v-if="x.type === 'wdm11' && f_calculateWdm11RemoveApproveLimit(x) && f_userHasPermissionToEditThisModel(x.department, x.type)" @click="f_removeApproveWdm11(x)">
                          <i class="fa fa-edit"></i> Onay işlemini geri al
                        </b-dropdown-item> -->
                        <template>
                          <!-- <b-dropdown-item v-if="StoreTest.mode" @click="SendThisDataToWebserviceInspection(x, 1, x.type)">
                          <i class="fa fa-edit"></i> Hastanın açık protokollerine gönder (önce hazırla)
                        </b-dropdown-item> -->
                          <!--                         <b-dropdown-item @click="SendThisDataToWebserviceInspection(x, 0, x.type)">
                          <i class="fa fa-edit"></i> Hastanın açık protokollerine gönder (direkt gönder)
                        </b-dropdown-item> -->
                        </template>
                      </b-dropdown>
                    </b-col>
                    <b-col cols="3" style="padding-left: 0px;">
                      <strong style="color: green;" v-if="x.date">
                        {{ DateFormat(x.date) }} -
                        <span v-if="d_allUsers[x.created_by]"> {{ w_presentation ? 'DOKTOR AD SOYAD' : d_allUsers[x.created_by].first_name + ' ' + d_allUsers[x.created_by].last_name }} </span>
                        <span v-if="x.status === 'webservice'">HBYS</span>
                        <br>
                        <b-badge variant="success"> {{ f_calculateDepartmentName(x.department) ? f_calculateDepartmentName(x.department) : x.department }} </b-badge>
                      </strong>
                    </b-col>
                    <b-col cols="4" style="color: #407a9a;">
                      <strong v-if="x.label">{{ x.label }}</strong>
                      <template v-if="x.status === 'webservice'">
                        <template v-if="x.hims_protocol_no">
                          Protokol No: {{ x.hims_protocol_no }} /
                        </template>
                        <template v-if="x.hims_test_name">
                          Test Adı: {{ x.hims_test_name }} /
                        </template>
                        <template v-if="x.approve_date">
                          Onay Tarihi: {{ DateFormat(x.approve_date) }} /
                        </template>
                        <template v-if="x.hims_state_status">
                          Rapor Durumu: {{ x.hims_state_status }}
                        </template>
                      </template>
                    </b-col>
                    <b-col cols="2" style="padding-left: 0px; padding-right: 0px;">
                      <b-badge variant="success" class="pull-right" :style="patient_wdm_list[x.reference_wdm.key] && patient_wdm_list[x.reference_wdm.key].color ? 'padding: 5px; color: black; background-color: ' + patient_wdm_list[x.reference_wdm.key].color : 'padding: 5px; color: black; background-color: #5db5bd;'"> {{ patient_wdm_list[x.reference_wdm.key].name.label.toLocaleUpperCase('en') + ' v' + x.reference_wdm.version }} </b-badge>
                    </b-col>
                    <b-col cols="2" style="color: green;">
                      <b-button v-if="f_userHasPermissionToEditThisModel(x.department, x.type, x.hospital_group, x.hospital_id) && f_calculateWdm10And11And12Edit(x) && f_checkWdmrWebServiceEditable(x)" class="pull-right" size="md" title="Düzenleme Modu" :variant="edit_index === x_ind ? 'warning' : 'secondary'" :style="edit_index === x_ind && d_editInThisLocation ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_editInThisLocation(x_ind, x.type)">
                        <img src="@/icon/3162318.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="view_type_wisdom_data === 'small'" class="pull-right" size="md" title="Veri Aralığı Genişliği" :variant="view_type_wisdom_data_list.indexOf(x_ind) !== -1 ? 'warning' : 'secondary'" :style="view_type_wisdom_data_list.indexOf(x_ind) !== -1 ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="CalculateShowWisdomDataClassOne(x_ind)">
                        <img src="@/icon/629153.png" style="width: 2.5em;" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-row :class="[CalculateShowWisdomDataClass(x_ind)]" id="wisdom-data" :style="edit_index === x_ind && d_editInThisLocation ? 'border: solid 10px #f44336; margin: 0px 3px 0px 3px;' : ''">
                  <b-col cols="12">
                    <template v-if="edit_index === x_ind && d_editInThisLocation">
                      <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
                    </template>
                    <template v-else>
                      <wisdom-data-show :change="change" :view_mode="view_mode" :data="x" :data_type="x.type" :option_data="patient_wdm_list[x.reference_wdm.key]" :user_brief_list="user_brief_list[x.reference_wdm.key]" :brief_mode="brief_mode"></wisdom-data-show>
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </template>
        </b-col>
      </b-row>
      <b-row v-if="show_new_wisdom_data">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-button class="pull-right" variant="secondary" @click="show_new_wisdom_data = false">Kayıt İşlemi İptal</b-button>
            </b-col>
          </b-row>
          <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
        </b-col>
      </b-row>
      <b-row v-if="show_new_wisdom_data">
        <b-col cols="12">
          <div class="pull-right">
            <b-button variant="success" v-on:click="f_saveWdm()">{{ $t('wdm16.4061') }}</b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_showDrugModeTimeLine" @close="d_showDrugModeTimeLine = false" :width="'1000'">
      <h3 slot="header">
        İlaç Zaman Çizelgesi
      </h3>
      <div slot="body">
        <time-line :p_drugAdjustMode="true" :p_wdmrDateAdjustData="d_wdmrDateAdjustData" :timeline_data="d_drugModeTimeLineData" :year_month_mode="d_timeLineYearMonthMode" :mother_children="mother_children"></time-line>
      </div>
      <div slot="footer">
        <!-- <b-button variant="success" @click="f_saveDrugDateAdjust()">{{ $t('wdm16.4061') }}</b-button> -->
        <b-button variant="danger" @click="d_showDrugModeTimeLine = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showWdmModal" @close="d_showWdmModal = false" :width="'1000'">
      <h3 slot="header">
        <img v-if="patient.sex === '0'" src="../../../static/img/avatars/man.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" />
        <img v-else src="../../../static/img/avatars/woman.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" />
        {{ w_presentation ? 'HASTA AD SOYAD' : patient.firstname.toLocaleUpperCase('tr') + ' ' + patient.lastname.toLocaleUpperCase('tr') }},
        <small> {{ option_data[data_type].name.label }} {{ 'v' + option_data[data_type].version }}</small>
      </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
        <!--               <wisdom-data-modal-template-study :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]"></wisdom-data-modal-template-study>
        -->
      </div>
      <div slot="footer">
        <b-button variant="success" v-on:click="f_saveWdm()">{{ $t('wdm16.4061') }}</b-button>
        <b-button variant="danger" @click="d_showWdmModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showHimsProtocols" @close="d_showHimsProtocols = false" :width="'1500'">
      <h3 slot="header">
        Hasta HBYS Başvuru Protokolleri,
        <img v-if="patient.sex === '0'" src="../../../static/img/avatars/man.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" />
        <img v-else src="../../../static/img/avatars/woman.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" /> {{ w_presentation ? 'HASTA AD SOYAD' : patient.firstname.toLocaleUpperCase('tr') + ' ' + patient.lastname.toLocaleUpperCase('tr') }},
        <small> {{ option_data[data_type].name.label }} </small>
      </h3>
      <div slot="body">
        <hims-protocol-details :protocolOpenOrClosedState="'opened'" :prepared_hims_data="prepared_hims_data" :hims_protocol_list="hims_protocol_list" :patient_open_protocol_list="patient_open_protocol_list" :patient="patient"></hims-protocol-details>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showHimsProtocols = false">{{ $t('wdm16.4053') }}</button>
        <b-button variant="success" v-on:click="f_saveWdmFromHimsScreen()">{{ $t('wdm16.4061') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showLabOrderModal" @close="d_showLabOrderModal = false" :width="'1500'">
      <h3 slot="header">
        Lab İstem Ekranı
      </h3>
      <div slot="body">
        <patient-order-widget :p_patient="patient" :p_orderType="'laboratory'"></patient-order-widget>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showLabOrderModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <!-- <modal v-if="d_showScreenSettings" @close="d_showScreenSettings = false" :width="'1000'">
      <div slot="header">
        Ekran Ayarları
      </div>
      <div slot="body">
        <b-card bg-variant="secondary" no-body>
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            Ekran Modu Ayarları
          </b-card-header>
          <b-row>
            <b-col cols="6"> Veri Modeli Seçimi </b-col>
            <b-col cols="6"> </b-col>
          </b-row>
        </b-card>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showScreenSettings = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal> -->
    <modal v-if="d_showRadOrderModal" @close="d_showRadOrderModal = false" :width="'1500'">
      <h3 slot="header">
        Rad İstem Ekranı
      </h3>
      <div slot="body">
        <patient-order-widget :p_patient="patient" :p_orderType="'radiology'"></patient-order-widget>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showRadOrderModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showPatientReportInformationModal" @close="d_showPatientReportInformationModal = false" :width="'1500'">
      <h3 slot="header">
        Rapor Listeleme Ekranı
      </h3>
      <div slot="body">
        <patient-report-information :p_patient="patient"></patient-report-information>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showPatientReportInformationModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showWdmrReportingModal" @close="d_showWdmrReportingModal = false" :width="'1500'">
      <h3 slot="header">
        Rapor Listeleme Ekranı
      </h3>
      <div slot="body">
        <wdmr-report :p_wdmrList="d_wdmrReportData.wisdom_data" :p_optionDataObj="d_wdmrReportData.option_data" :p_patientData="patient"></wdmr-report>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showWdmrReportingModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showForceGetHimsData" @close="d_showForceGetHimsData = false" :width="'1000'">
      <h3 slot="header">
        Muayene Bilgilerini Tekrar Yüklemek için Departman Seçiniz
      </h3>
      <div slot="body">
        <b-row>
          <b-col cols="12" class="text-center">
            <button type="button" class="btn btn-secondary" @click="d_forceGetHimsDataOnlyDepartments = []">Seçimi İptal Et</button>
            <button type="button" class="btn btn-primary" @click="d_forceGetHimsDataOnlyDepartments.indexOf(d_userActiveDepartment) === -1 ? d_forceGetHimsDataOnlyDepartments.push(d_userActiveDepartment) : {}">Departmanımı Seçime Ekle</button>
            <button type="button" class="btn btn-success" @click="f_selectAllDepartmentsForForceGet()">Tümünü Seç</button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5" style="overflow-y: auto; height: 500px;">
            <b-card no-body>
              <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                Eklenebilecek Departmanlar
              </b-card-header>
              <template v-for="(dep, dep_ind) in d_allDepartmentsList" v-if="d_forceGetHimsDataOnlyDepartments.indexOf(dep.value) === -1">
                <b-row :key="'force_dep_nonadded_'+ dep_ind">
                  <b-col onMouseOver="this.style.color='green'" onMouseOut="this.style.color='black'" cols="10" @click="f_addRemoveDepartmentToForceList(dep.value)" style="cursor: pointer; ">
                    <i class="fa fa-arrow-right"></i>{{ dep.label }}
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
          <b-col cols="2">
          </b-col>
          <b-col cols="5" style="overflow-y: auto; height: 500px;">
            <b-card no-body>
              <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                Eklenen Departmanlar
              </b-card-header>
              <template v-for="(dep, dep_ind) in d_allDepartmentsList" v-if="d_forceGetHimsDataOnlyDepartments.indexOf(dep.value) !== -1">
                <b-row :key="'force_dep_added_'+ dep_ind">
                  <b-col onMouseOver="this.style.color='red'" onMouseOut="this.style.color='black'" cols="10" @click="f_addRemoveDepartmentToForceList(dep.value)" style="cursor: pointer; ">
                    <i class="fa fa-arrow-left"></i>{{ dep.label }}
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary" @click="f_forceGetHimsData(patient.id, 'medical_examination')">{{ $t('wdm16.4196') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showForceGetHimsData = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal id="patient-hospital-show-modal" v-model="d_showPatientHospitalDataModal" title="Hasta & Hastane Detayları" centered header-bg-variant="success" header-text-variant="dark">
      <b-row v-if="d_patientHospitalDataModalData.id && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
        <b-col sm="12" lg="3">id</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.id }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.patient_no">
        <b-col sm="12" lg="3">Hasta No</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.patient_no }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.firstname">
        <b-col sm="12" lg="3">Ad</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.firstname }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.lastname">
        <b-col sm="12" lg="3">Soyisim</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.lastname }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.cinsiyet">
        <b-col sm="12" lg="3">Cinsiyet</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.cinsiyet }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.address">
        <b-col sm="12" lg="3">Adres</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.address }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.il">
        <b-col sm="12" lg="3">İl</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.il }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.ülke">
        <b-col sm="12" lg="3">Ülke</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.ülke }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.mobile_phone">
        <b-col sm="12" lg="3">Mobil Tel</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.mobile_phone }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.pasaport_number">
        <b-col sm="12" lg="3">Pasaport No</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.pasaport_number }}</b-col>
      </b-row>
      <b-row v-if="d_patientHospitalDataModalData.birth_date">
        <b-col sm="12" lg="3">Doğum Tarihi</b-col>
        <b-col sm="12" lg="9">{{ d_patientHospitalDataModalData.birth_date }}</b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="d_showPatientHospitalDataModal = false">{{ $t('wdm16.11212') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import Vue from 'vue';
import SchemaService from "@/services/schema";
import { ClsWdm } from "@/services/public/wdm";
import Datepicker from "wisdom-vue-datepicker";
import {
  default as WdmrSchemaMulti
} from '@/components/widgets/WdmrSchemaMulti';
import {
  default as MultiWdmr
} from '@/components/widgets/MultiWdmr';
import {
  default as HimsProtocolDetails
} from '@/components/widgets/HimsProtocolDetails';
import {
  default as TimeLine
} from '@/components/widgets/TimeLine';
import {
  default as PatientDrug
} from '@/components/widgets/PatientDrug';
import { mapGetters } from "vuex";
import {
  default as router
} from "@/router";
// services
import WebService from "@/services/webservice";
import WdmService from "@/services/wdm";
import PatientService from "@/services/patient";
import GlobalService from "@/services/global";
import DrugService from "@/services/drug";
import HospitalService from "@/services/hospital";
import OptionService from "@/services/option";
import WisdomDataService from "@/services/wisdom_data";
import UserService from "@/services/users";
import moment from "moment";
import {
  default as BmiGfrWidget
} from "@/components/widgets/BmiGfrWidget";
// import { API_BASE_URL } from '@/config';
// modals & widgets
import { ClsUserSettings } from '@/services/public/user';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataShow
} from "@/components/widgets/WisdomDataShow";
import {
  default as WisdomDataModal
} from "@/components/widgets/WisdomDataModal";
import { DateFunctions } from "@/services/public/date_functions";
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import {
  default as TreeList
} from '@/components/widgets/TreeList';
import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import {
  default as PatientOrderWidget
} from '@/components/widgets/PatientOrderWidget';
import {
  default as PatientReportInformation
} from '@/components/widgets/PatientReportInformation';
import {
  default as WdmrReport
} from '@/components/widgets/WdmrReport';
import vSelect from 'vue-select';
export default {
  name: "PatientWdmr",
  computed: {
    ...mapGetters({
      account_list: 'account_list',
      lang: "lang",
      StoreModal: 'modal',
      help: 'help',
      StoreLoading: 'loading',
      StoreTest: 'test',
      wdm: 'wdm',
      st_department: 'department'
    })
  },
  props: {
    patient: {
      type: Object,
      required: true
    },
    wdmr_add_edit: {
      type: Object,
      required: false
    },
    p_organizationTask: {
      type: Object,
      required: false
    },
    p_timeLine: {
      type: Object,
      required: false
    },
    screen_mode_index: {
      type: String,
      required: false
    },
    p_isModal: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      d_patientHospitalDataModalData: {},
      d_selectedAnatomySchemaList: [],
      d_showPatientHospitalDataModal: false,
      d_showPatientInfoMode: false,
      w_presentation: false,
      d_showDrugModeTimeLine: false,
      d_editInThisLocation: false,
      d_multiWdmrMode: 0,
      d_showForceGetHimsData: false,
      d_showHimsProtocols: false,
      d_showLabOrderModal: false,
      d_showMultiWdmrField: true,
      d_showPatientDataRecords: true,
      d_showPatientReportInformationModal: false,
      d_showRadOrderModal: false,
      d_showScreenSettings: false,
      d_showWdmModal: false,
      d_showWdmParameterManageModal: false,
      d_showWdmr11or12: { 'x': false },
      d_showWdmrReportingModal: false,
      d_wdmr10ShowMode: false,
      show_new_wisdom_data: false,
      d_wdmrReportData: {},
      bmi_gfr_change: { status: false },
      d_wdmrDateAdjustData: { 'data': {} },
      d_drugModeTimeLineData: [],
      d_selectedWdmr10: { 'id': '', 'index': '', 'status': {} },
      d_selectedModelsForDateInterval: [],
      d_loadingSentence: { 'f_getPatientRecords': { 'show': false, 'text': 'Veriler Getiriliyor' } },
      d_newScreenFormatName: '',
      d_showFastWdmrAddMode: 0,
      d_fastMode: { 'option_data': {}, 'data_type': '', 'wisdom_data': {} },
      d_fastWdmIconList: [],
      d_timeLineYearMonthMode: { 'year': 0, 'month': 0 },
      d_timeLineLeftToRight: [],
      d_forceGetHimsDataOnlyDepartments: [],
      d_timeLineMode: 0,
      brief_mode: 0,
      user_brief_list: {},
      d_screenModeWdmrSettings: {},
      d_wdmParameters: {
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'option_data': {},
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 }
      },
      mother_children: { 'change_status': 0, 'update_naming': 0, 'update_patient_wdmr': 0, 'wdmr10_data': {}, 'get_patient_records': 0, 'forceUpdate': 0, 'show_drug_date_adjust': 0 },
      d_patientDrugEditData: { 'status': 0, 'key': '', 'data_type': '' },
      d_showMode: 1,
      d_drugMode: 0,
      patient_open_protocol_list: [],
      d_allDepartmentsList: [],
      hims_protocol_list: {},
      prepared_hims_data: {},
      d_searchData: {
        'show_last_x_data_list': {},
        'started_at': '',
        'ending_at': ''
      },
      tree_list: {
        'module_and_model': {
          'show_items_on_top': false,
          'max_height': '200',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'multi' // ['one', 'multi']
        },
        'department': {
          'show_selected_item_parents': true,
          'eligible_items_list': [],
          'show_items_on_top': false,
          'max_height': '200',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'multi' // ['one', 'multi']
        }
      },
      view_type_wisdom_data_list: [],
      view_type_wisdom_data: 'small',
      change: "0",
      wisdom_op: "",
      view_mode: "table",
      wisdom_data: {},
      d_fastWisdomData: {},
      user: {},
      patient_data_list: [],
      reverse_patient_data_list: [],
      patient_wdm_list: {},
      data_type: "",
      option_data: {},
      hospitalData: {},
      WebserviceProtocolInsuranceData: require('@/options/webservice').ProtocolInsuranceData,
      d_selectedWdmList: [],
      d_selectedDepartmentList: [],
      d_allUsers: {},
      d_dateListMode: 'first_old',
      brief_type: 'show_not_include_brief_data',
      edit_index: '',
      d_userScreenModes: [],
      d_selectedScreenModeIndex: '',
      d_isActiveBringingWdmrListWhileMounting: 0,
      d_wdmDetails: {},
      d_patientVisitedDepartments: [],
      d_userActiveDepartment: '',
      d_wdmr11or12List: { 'intermittently_followup': [], 'cycle_followup': [], 'daily_followup': [], 'show_wdmr10': [] },
      d_optionData: {},
      d_dateAdjustObj: {},
      d_drugModeDepartmentFilterType: 'selected',
      d_drugModeDepartmentFilterList: []
    };
  },
  created: function () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    let all_users = JSON.parse(localStorage.getItem('all_users'));
    for (let i in all_users) {
      this.d_allUsers[all_users[i].username] = all_users[i];
    }
    this.hospitalData = JSON.parse(localStorage.getItem('hospital_data'));
    this.user = JSON.parse(localStorage.getItem("user"));
    this.f_createUserBriefList();
    //********** eligible departments that the user can see
    let user_already_departments = [];
    let eligible_departments = [];
    for (let i in this.user.position_department_title) {
      let x = { 'label': this.user.position_department_title[i].department.label, 'value': this.user.position_department_title[i].department.value, 'patient_visited': 0 };
      if (this.user.position_department_title[i].active) {
        this.d_userActiveDepartment = this.user.position_department_title[i].department.value;
        this.d_drugModeDepartmentFilterList.push(this.d_userActiveDepartment);
      }
      user_already_departments.push(this.user.position_department_title[i].department.value);
      eligible_departments.push(x);
    }
    if (this.user.wdm_department) {
      for (let i in this.user.wdm_department.list) {
        user_already_departments.push(this.user.wdm_department.list[i].value);
        eligible_departments.push({ 'label': this.user.wdm_department.list[i].label, 'value': this.user.wdm_department.list[i].value, 'patient_visited': 0 });
      }
    }
    for (let i in this.patient.visited_departments) {
      this.d_patientVisitedDepartments.push(this.patient.visited_departments[i].value);
      if (user_already_departments.indexOf(this.patient.visited_departments[i].value) === -1) {
        eligible_departments.push(this.patient.visited_departments[i]);
      } else {
        for (let k in eligible_departments) {
          if (this.patient.visited_departments[i].value === eligible_departments[k].value) {
            eligible_departments[k].patient_visited = 1;
            break;
          }
        }
      }
    }
    this.tree_list.department.eligible_items_list = eligible_departments;
    // this.tree_list.department.selected_items_data_list = eligible_departments;
    //********* user department store
    if (this.p_organizationTask) {
      this.d_selectedDepartmentList = [];
      this.d_selectedDepartmentList.push(this.p_organizationTask.data.general.selected_hospital_department.val.value);
    } else {
      if (this.user && this.user.settings && this.user.settings.department && this.user.settings.department.ful && this.user.settings.department.ful['patient_follow']) {
        this.$store.commit('department', { 'department_list': this.user.settings.department.ful['patient_follow'].list, 'ful': 'patient_follow', 'status': 'not_change' });
        // let selected_dep_list = [];
        this.tree_list.department.selected_items_data_list = this.user.settings.department.ful['patient_follow'].list;
        for (let i in this.user.settings.department.ful['patient_follow'].list) {
          if (this.user.settings.department.ful['patient_follow'].list[i].selected === 1) {
            this.d_selectedDepartmentList.push(this.user.settings.department.ful['patient_follow'].list[i].value);
            // selected_dep_list.push({ 'label': this.user.settings.department.ful['patient_follow'].list[i].label, 'value': this.user.settings.department.ful['patient_follow'].list[i].value });
          }
        }
        // this.tree_list.department.selected_items_data_list = selected_dep_list;
      } else {
        let department_list = [];
        for (let i in this.user.position_department_title) {
          let x = { 'label': this.user.position_department_title[i].department.label, 'value': this.user.position_department_title[i].department.value, 'selected': 0 };
          if (this.user.position_department_title[i].active) {
            x.selected = 1;
            this.d_selectedDepartmentList.push(this.user.position_department_title[i].department.value);
          }
          department_list.push(x);
        }
        if (this.user.wdm_department) {
          for (let i in this.user.wdm_department.list) {
            department_list.push({ 'label': this.user.wdm_department.list[i].label, 'value': this.user.wdm_department.list[i].value });
          }
        }
        this.tree_list.department.selected_items_data_list = department_list;
        this.$store.commit('department', { 'department_list': department_list, 'ful': 'patient_follow' });
      }
    }
    let user_already_selected_dept = [];
    for (let i in this.tree_list.department.selected_items_data_list) {
      user_already_selected_dept.push(this.tree_list.department.selected_items_data_list[i].value);
    }
    for (let i in this.patient.visited_departments) {
      if (user_already_selected_dept.indexOf(this.patient.visited_departments[i].value) === -1) {
        this.tree_list.department.selected_items_data_list.push(this.patient.visited_departments[i]);
      }
    }
    //***********
    if (this.user && this.user.settings && this.user.settings.wdm && this.user.settings.wdm.ful && this.user.settings.wdm.ful['patient_follow']) {
      this.$store.commit('wdm', { 'wdm_list': this.user.settings.wdm.ful['patient_follow'].list, 'ful': 'patient_follow', 'status': 'not_change' });
      for (let i in this.user.settings.wdm.ful['patient_follow'].list) {
        if (this.user.settings.wdm.ful['patient_follow'].list[i].selected === 1) {
          this.d_selectedWdmList.push(this.user.settings.wdm.ful['patient_follow'].list[i].value);
        }
      }
      this.tree_list.module_and_model.selected_items_data_list = JSON.parse(JSON.stringify(this.user.settings.wdm.ful['patient_follow'].list));
    }
    let today = new Date();
    this.d_searchData.started_at = moment(today).format('YYYY-MM-DD');
    this.d_searchData.ending_at = this.d_searchData.started_at;
    if (this.user.settings && this.user.settings.screen_mode && this.user.settings.screen_mode.patient_follow) {
      this.d_userScreenModes = JSON.parse(JSON.stringify(this.user.settings.screen_mode.patient_follow.list));
      let det = false;
      if (this.p_timeLine && this.p_timeLine.timeline_no) {
        // console.log('this.p_timeLine: ', this.p_timeLine);
        // console.log('this.d_userScreenModes: ', this.d_userScreenModes);
        for (let i in this.d_userScreenModes) {
          if (this.d_userScreenModes[i][this.p_timeLine.timeline_no]) {
            this.f_selectTheScreenMode(i);
            det = true;
            break;
          }
        }
      }
      if (this.screen_mode_index !== undefined && this.screen_mode_index !== '') {
        this.f_selectTheScreenMode(this.screen_mode_index);
        det = true;
      }
      if (!det) {
        let det_default = false;
        for (let i in this.d_userScreenModes) {
          if (this.d_userScreenModes[i].default) {
            this.f_selectTheScreenMode(i);
            det_default = true;
            break;
          }
        }
        if (!det_default) {
          for (let i in this.d_userScreenModes) {
            this.f_selectTheScreenMode(i);
            break;
          }
        }
      }
    }
    this.f_getWdmDetails();
  },
  mounted () {
    this.$options.sockets.onmessage = function (message) {
      let socket_data = JSON.parse(message.data);
      if (socket_data.type && ['wdmr11_or_wdmr12_transfer'].indexOf(socket_data.type) !== -1 && this.d_drugMode) {
        if (this.patient.id === socket_data.data.patient_id) {
          this.f_updateWdmr11or12FromSocket(socket_data.data);
        }
      } else if (socket_data.type && ['add_edit_delete_wdmr10'].indexOf(socket_data.type) !== -1 && this.d_drugMode) {
        // console.log(socket_data.data);
        if (this.patient.id === socket_data.data.patient_id) {
          this.f_addEditDeleteWdmr10FromSocket(socket_data.data);
        }
      }
    };
  },
  methods: {
    f_showPatientHospitalDataModal: function (hos_data) {
      this.d_patientHospitalDataModalData = {};
      this.d_patientHospitalDataModalData = hos_data;
      this.d_showPatientHospitalDataModal = true;
    },
    f_multiWdmrMode: function () {
      if (this.d_multiWdmrMode) {
        this.d_multiWdmrMode = 0;
      } else {
        this.d_multiWdmrMode = 1;
      }
      this.$forceUpdate();
    },
    f_saveDrugDateAdjust: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tarih düzenleme işlemi gerçekleştiriliyor.' } });
      let data = { 'list': [] };
      for (let i in this.d_wdmrDateAdjustData.data) {
        if (parseInt(this.d_wdmrDateAdjustData.data[i].date_interval) !== 0) {
          data.list.push({ 'wdmr_key': i, 'date_interval': this.d_wdmrDateAdjustData.data[i].date_interval });
        }
      }
      if (data.list.length > 0) {
        DrugService.date_adjust_with_connection(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              if (this.d_wdmr10ShowMode) {
                this.f_getPatientRecords();
              } else {
                this.f_getWdmr11orWdmr12List();
              }
              this.d_showDrugModeTimeLine = false;
              // this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.d_showDrugModeTimeLine = false;
              alert('error ', resp.data.message);
            }
          });
      } else {
        this.d_showDrugModeTimeLine = false;
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_showDrugModeTimeLine: function (follow_type) {
      this.d_drugModeTimeLineData = [
        [],
        []
      ];
      this.d_drugModeTimeLineData[0].push([{}]);
      this.d_drugModeTimeLineData[1].push([{ "date": "", "background_color": "#0f9", "value": "wdmr11or12", "label": "Tedaviler" }]);
      let tmp_arr = [];
      let tmp_obj = {};
      // for (let follow_type in this.d_wdmr11or12List) {
      for (let d in this.d_wdmr11or12List[follow_type]) {
        this.d_drugModeTimeLineData[0].push([{ 'date': this.d_wdmr11or12List[follow_type][d].date }]);
        tmp_arr = [];
        for (let k in this.d_wdmr11or12List[follow_type][d].list) {
          let wdmr_data = this.d_wdmr11or12List[follow_type][d].list[k];
          tmp_obj = {
            'bucket': 'option',
            'value': wdmr_data.type + '_' + wdmr_data.id,
            'label': wdmr_data.label,
            'date': wdmr_data.date,
            'wdm_key': wdmr_data.reference_wdm.key,
            'department': wdmr_data.department,
            'type': wdmr_data.type,
            'wdmr_connection': wdmr_data.wdmr_connection
          };
          tmp_arr.push(tmp_obj);
        }
        this.d_drugModeTimeLineData[1].push(tmp_arr);
      }
      // }
      this.d_showDrugModeTimeLine = true;
    },
    f_changeWdmr10ShowMode: function () {
      if (this.d_wdmr10ShowMode) {
        this.d_wdmr10ShowMode = false;
        this.f_getWdmr11orWdmr12List();
      } else {
        this.d_wdmr11or12List = { 'intermittently_followup': [], 'cycle_followup': [], 'daily_followup': [], 'show_wdmr10': [] };
        this.d_selectedWdmList = ['wdm10'];
        this.d_searchData.show_last_x_data_list = { 'wdm10': { 'val': 'all' } };
        this.f_getPatientRecords();
      }
    },
    f_addEditDeleteWdmr10FromSocket: function (socket_data) {
      let remove_data = socket_data.remove_data;
      let add_data = socket_data.add_data;
      let tmp_data = [];
      // console.log(remove_data);
      if (remove_data.date_list && remove_data.date_list.length > 0) {
        tmp_data = this.f_removeDataFromWdmr10FromSocket(remove_data);
      } else {
        tmp_data = JSON.parse(JSON.stringify(this.d_wdmr11or12List));
      }
      if (Object.keys(add_data).length > 0) {
        // TODO FIX will activate after checking model type for socket
        // tmp_data = this.f_addDataToWdmr10FromSocket(tmp_data, add_data);
      }
      // TODO FIX will activate after checking model type for socket
      // tmp_data.sort((a, b) => a.date.localeCompare(b.date));
      this.d_wdmr11or12List = tmp_data;
      this.f_motherChildren('update_naming');
    },
    f_removeDataFromWdmr10FromSocket: function (remove_data) {
      let model_types = ['intermittently_followup', 'cycle_followup', 'daily_followup'];
      let tmp_data = {};
      for (let m in model_types) {
        for (let d in this.d_wdmr11or12List[model_types[m]]) {
          if (remove_data.date_list.indexOf(this.d_wdmr11or12List[model_types[m]][d].date) === -1) {
            tmp_data[model_types[m]].push(this.d_wdmr11or12List[model_types[m]][d]);
          } else {
            let new_tmp_list = [];
            for (let k in this.d_wdmr11or12List[model_types[m]][d].list) {
              let old_data = this.d_wdmr11or12List[model_types[m]][d].list[k];
              let old_key = old_data.type + '_' + old_data.id;
              if (remove_data.key_list.indexOf(old_key) === -1) {
                new_tmp_list.push(old_data);
              }
            }
            if (new_tmp_list.length > 0) {
              tmp_data[model_types[m]].push({ 'date': this.d_wdmr11or12List[model_types[m]][d].date, 'list': new_tmp_list });
            }
          }
        }
      }
      return tmp_data;
    },
    f_addDataToWdmr10FromSocket: function (tmp_data, add_data) {
      for (let d in tmp_data) {
        if (add_data[tmp_data[d].date]) {
          for (let t in add_data[tmp_data[d].date]) {
            if (this.d_drugModeDepartmentFilterType === 'all' || (this.d_drugModeDepartmentFilterType === 'selected' || this.d_drugModeDepartmentFilterList.indexOf(add_data[tmp_data[d].date][t].department) !== -1)) {
              if (!this.d_wdmr10ShowMode) {
                tmp_data[d].list.push(add_data[tmp_data[d].date][t]);
              } else {
                if (this.d_selectedWdmr10 && this.d_selectedWdmr10.id && this.d_selectedWdmr10.id !== '' && 'wdm10_' + this.d_selectedWdmr10.id === add_data[tmp_data[d].date][t].parent_key) {
                  tmp_data[d].list.push(add_data[tmp_data[d].date][t]);
                }
              }
            }
          }
          delete add_data[tmp_data[d].date];
        }
      }
      for (let n_date in add_data) {
        let tmp_check_dept_list = [];
        for (let b in add_data[n_date]) {
          if (this.d_drugModeDepartmentFilterType === 'all' || (this.d_drugModeDepartmentFilterType === 'selected' || this.d_drugModeDepartmentFilterList.indexOf(add_data[n_date][b].department) !== -1)) {
            // tmp_check_dept_list.push(add_data[n_date][b]);
            if (!this.d_wdmr10ShowMode) {
              tmp_check_dept_list.push(add_data[n_date][b]);
            } else {
              if (this.d_selectedWdmr10 && this.d_selectedWdmr10.id && this.d_selectedWdmr10.id !== '' && 'wdm10_' + this.d_selectedWdmr10.id === add_data[n_date][b].parent_key) {
                tmp_check_dept_list.push(add_data[n_date][b]);
              }
            }
          }
        }
        if (tmp_check_dept_list.length > 0) {
          tmp_data.push({ 'date': n_date, 'list': tmp_check_dept_list });
        }
      }
      return tmp_data;
    },
    f_updateWdmr11or12FromSocket: function (socket_data) {
      let model_types = ['intermittently_followup', 'cycle_followup', 'daily_followup'];
      for (let s in socket_data.list) {
        let new_data = socket_data.list[s].wdmr11or12list;
        // console.log('new_data', new_data);
        let socket_date = socket_data.list[s].date;
        let tmp_list = { 'intermittently_followup': [], 'cycle_followup': [], 'daily_followup': [] };
        let new_wdmr12_parent_list = { 'intermittently_followup': [], 'cycle_followup': [], 'daily_followup': [] };
        let new_wdmr12_list = { 'intermittently_followup': [], 'cycle_followup': [], 'daily_followup': [] };
        let new_wdmr11_list = { 'intermittently_followup': [], 'cycle_followup': [], 'daily_followup': [] };
        // let tmp_list = [];
        // let new_wdmr12_parent_list = []
        // let new_wdmr12_list = []
        // let new_wdmr11_list = []
        for (let i in new_data) {
          if (this.d_drugModeDepartmentFilterType === 'all' || (this.d_drugModeDepartmentFilterType === 'selected' || this.d_drugModeDepartmentFilterList.indexOf(new_data[i].department) !== -1)) {
            let drug_model_type = new_data[i].data.general.model.val.value;
            tmp_list[drug_model_type].push(new_data[i]);
            let new_data_key = new_data[i].type + '_' + new_data[i].id;
            if (new_data[i].type === 'wdm12') {
              new_wdmr12_parent_list[drug_model_type].push(new_data[i].parent_key);
              new_wdmr12_list[drug_model_type].push(new_data_key);
            } else if (new_data[i].type === 'wdm11') {
              new_wdmr11_list[drug_model_type].push(new_data_key);
            }
          }
          // tmp_list.push(new_data[i]);
          // console.log('tmp_list', tmp_list);
        }
        for (let m in model_types) {
          if (tmp_list[model_types[m]].length > 0) {
            for (let d in this.d_wdmr11or12List[model_types[m]]) {
              if (socket_date === this.d_wdmr11or12List[model_types[m]][d].date) {
                // console.log('1188', this.d_wdmr11or12List[model_types[m]][d].list);
                for (let k in this.d_wdmr11or12List[model_types[m]][d].list) {
                  let old_data = this.d_wdmr11or12List[model_types[m]][d].list[k];
                  let old_key = old_data.type + '_' + old_data.id;
                  // console.log('1192', old_key);
                  if (old_data.type === 'wdm12') {
                    if (new_wdmr12_list[model_types[m]].indexOf(old_key) !== -1) {
                      continue;
                    } else if (new_wdmr11_list[model_types[m]].indexOf(old_data.parent_key) !== -1) {
                      continue;
                    } else {
                      tmp_list[model_types[m]].push(old_data);
                    }
                  } else if (old_data.type === 'wdm11') {
                    if (new_wdmr12_parent_list[model_types[m]].indexOf(old_key) !== -1) {
                      continue;
                    } else if (new_wdmr11_list[model_types[m]].indexOf(old_key) !== -1) {
                      continue;
                    } else {
                      tmp_list[model_types[m]].push(old_data);
                    }
                  }
                }
                this.d_wdmr11or12List[model_types[m]][d].list = tmp_list[model_types[m]];
                this.f_motherChildren('update_naming');
                break;
              }
            }
          }
        }
      }
    },
    f_getWdmr11orWdmr12List: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Datalar getiriliyor.' } });
      this.d_showWdmr11or12.x = false;
      let data = {};
      data.patient_id = this.patient.id;
      data.department_filter_type = this.d_drugModeDepartmentFilterType;
      data.department_filter_list = this.d_drugModeDepartmentFilterList;
      WisdomDataService.get_patient_wdmr11_or_wdmr12(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_wdmr11or12List = resp.data.result.list;
            this.f_calculateOptionData();
            this.$forceUpdate();
          } else {
            alert('error:' + resp.data.message);
          }
        });
    },
    f_calculateOptionData: function () {
      // console.log('236');
      let key_list = [];
      for (let i in this.d_wdmr11or12List) {
        for (let m in this.d_wdmr11or12List[i]) {
          for (let k in this.d_wdmr11or12List[i][m].list) {
            if (key_list.indexOf(this.d_wdmr11or12List[i][m].list[k].reference_wdm.key) === -1 && !this.d_optionData[this.d_wdmr11or12List[i][m].list[k].reference_wdm.key]) {
              key_list.push(this.d_wdmr11or12List[i][m].list[k].reference_wdm.key);
            }
          }
        }
      }
      if (key_list.length > 0) {
        GlobalService.get_multiple_cb_documents({ 'key_list': key_list, 'bucket': 'option' })
          .then(resp => {
            if (resp.data.status === 'success') {
              for (let i in resp.data.result) {
                this.d_optionData[i] = {};
                this.d_optionData[i][resp.data.result[i].parent] = resp.data.result[i];
              }
            }
            setTimeout(function () {
              this.d_drugMode = 1;
              this.d_showWdmr11or12.x = true;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.$forceUpdate();
            }.bind(this), 50)
          });
      } else {
        setTimeout(function () {
          this.d_drugMode = 1;
          this.d_showWdmr11or12.x = true;
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.$forceUpdate();
        }.bind(this), 50)
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_changeViewMode: function () {
      if (this.view_mode === 'table') {
        this.view_mode = 'sentence';
      } else if (this.view_mode === 'sentence') {
        this.view_mode = 'polestar';
      } else if (this.view_mode === 'polestar') {
        this.view_mode = 'wdm_specific';
      } else if (this.view_mode === 'wdm_specific') {
        this.view_mode = 'table';
      }
    },
    f_getWdmDetails: function () {
      let modules = JSON.parse(localStorage.getItem('modules'));
      this.f_turnOverModules(modules['list']);
    },
    f_turnOverModules: function (list_data) {
      for (let i in list_data) {
        if (list_data[i].is_model) {
          if (!this.d_wdmDetails[list_data[i].value]) {
            this.d_wdmDetails[list_data[i].value] = {
              'color': list_data[i].color ? list_data[i].color : ''
            }
          }
        }
        if (list_data[i].list && list_data[i].list.length > 0) {
          this.f_turnOverModules(list_data[i].list);
        }
      }
    },
    f_showThisChars: function (text, count) {
      let str = '';
      if (text) {
        text = text.toString();
        let x = text.length > count ? count : text.length;
        for (let i = 0; i < x; i++) {
          if (text[i]) {
            str += text[i];
          } else {
            str += '.';
          }
        }
        if (text.length > count) {
          str += '...';
        }
      }
      return str;
    },
    f_selectModelForDateInterval: function (model_value) {
      if (this.d_selectedModelsForDateInterval.indexOf(model_value) === -1) {
        this.d_selectedModelsForDateInterval.push(model_value);
      } else {
        this.d_selectedModelsForDateInterval.splice(this.d_selectedModelsForDateInterval.indexOf(model_value), 1);
      }
      this.$forceUpdate();
    },
    f_showUpdateMultiWdmrMode: function () {
      this.d_multiWdmrMode = 0;
      setTimeout(function () {
        this.d_multiWdmrMode = 1;
      }.bind(this), 50);
    },
    f_selectTheScreenMode: function (mode_ind) {
      this.d_selectedScreenModeIndex = mode_ind;
      let selected_mode = JSON.parse(JSON.stringify(this.d_userScreenModes[mode_ind]));
      // console.log(selected_mode)
      this.d_selectedScreenModeIndex = mode_ind;
      this.d_newScreenFormatName = selected_mode.label;
      this.d_searchData.show_last_x_data_list = selected_mode.show_last_x_data_list ? selected_mode.show_last_x_data_list : {};
      // console.log(this.d_searchData.show_last_x_data_list);
      // Below code must be below of the upper code. Because there is a watch code for 'd_selectedWdmList' related with "this.d_searchData.show_last_x_data_list"
      this.d_selectedWdmList = selected_mode.d_selectedWdmList;
      this.d_drugMode = selected_mode.d_drugMode ? 1 : 0;
      this.d_multiWdmrMode = selected_mode.d_multiWdmrMode ? 1 : 0;
      if (selected_mode.d_multiWdmrMode) {
        this.f_showUpdateMultiWdmrMode();
      }
      this.d_isActiveBringingWdmrListWhileMounting = selected_mode.d_isActiveBringingWdmrListWhileMounting ? 1 : 0;
      this.brief_mode = selected_mode.brief_mode ? 1 : 0;
      this.d_showFastWdmrAddMode = selected_mode.d_showFastWdmrAddMode ? 1 : 0;
      this.brief_type = selected_mode.brief_type;
      this.d_selectedDepartmentList = selected_mode.d_selectedDepartmentList;
      this.d_timeLineMode = selected_mode.d_timeLineMode;
      this.d_timeLineType = selected_mode.d_timeLineType;
      this.d_timeLineYearMonthMode = selected_mode.d_timeLineYearMonthMode;
      this.view_mode = selected_mode.view_mode;
      this.d_fastWdmIconList = selected_mode.d_fastWdmIconList;
      this.view_type_wisdom_data = selected_mode.view_type_wisdom_data;
      this.d_showMode = selected_mode.d_showMode;
      // console.log(selected_mode.multi_wdmr_settings);
      if (selected_mode.multi_wdmr_settings) {
        this.d_screenModeWdmrSettings = selected_mode.multi_wdmr_settings;
        /*
          for (let wsi in selected_mode.multi_wdmr_settings) {
            this.d_multiWdmrData.wdmr_settings[wsi] = selected_mode.multi_wdmr_settings[wsi];
          }
        */
      }
      this.d_dateListMode = selected_mode.d_dateListMode;
      if (selected_mode.selected_items_data_list) {
        this.tree_list.module_and_model.selected_items_data_list = selected_mode.selected_items_data_list;
      }
      if (selected_mode.wdm_ful_patient_follow) {
        this.wdm.ful.patient_follow = selected_mode.wdm_ful_patient_follow;
      }
      // console.log(selected_mode.d_fastMode);
      if (this.d_showFastWdmrAddMode && selected_mode.d_fastMode && selected_mode.d_fastMode.data_type) {
        this.add_new_wisdom_data(selected_mode.d_fastMode.data_type, 'fast_mode');
      }
      // console.log(this.d_isActiveBringingWdmrListWhileMounting);
      if (this.d_isActiveBringingWdmrListWhileMounting) {
        this.f_getPatientRecords();
      }
      this.$forceUpdate();
    },
    f_makeScreenModeDefault: function (mode_ind) {
      for (let i in this.d_userScreenModes) {
        if (this.d_userScreenModes[i].default) {
          delete this.d_userScreenModes[i].default;
          break;
        }
      }
      this.d_userScreenModes[mode_ind]['default'] = 1;
      this.f_saveScreenModeList();
      this.$forceUpdate();
    },
    f_makeScreenTimeLineModeRelation: function (mode_ind, timeline_no) {
      for (let i in this.d_userScreenModes) {
        if (this.d_userScreenModes[i][timeline_no]) {
          delete this.d_userScreenModes[i][timeline_no];
          break;
        }
      }
      this.d_userScreenModes[mode_ind][timeline_no] = 1;
      this.f_saveScreenModeList();
      this.$forceUpdate();
    },
    f_saveScreenModeList: function () {
      let user_data = JSON.parse(localStorage.getItem('user'));
      ClsUserSettings.free_structure(user_data);
      if (!user_data.settings.screen_mode) {
        user_data.settings.screen_mode = { 'patient_follow': {} };
      }
      if (!user_data.settings.screen_mode.patient_follow) {
        user_data.settings.screen_mode['patient_follow'] = { 'list': [] };
      }
      user_data.settings.screen_mode['patient_follow'].list = this.d_userScreenModes;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı ayarlarınız güncelleniyor.' } });
      let data = { 'ful': 'patient_follow', 'screen_mode_list': this.d_userScreenModes, 'username': user_data.username };
      UserService.save_screen_mode_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            localStorage.setItem('user', JSON.stringify(user_data));
            this.user = user_data;
            alert(this.$t('wdm16.12713'));
          } else {
            alert(this.$t('wdm16.12714'), resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_deleteScreenMode: function (mode_ind) {
      this.d_userScreenModes.splice(mode_ind, 1);
    },
    f_addEditThisModeToUserScreenModes: function (type, mode_ind = '') {
      if (!this.d_screenModeWdmrSettings.multi_wdmr_schema) {
        this.d_screenModeWdmrSettings.multi_wdmr_schema = {};
      }
      if (!this.d_screenModeWdmrSettings.multi_wdmr_schema.d_selectedSchema) {
        this.d_screenModeWdmrSettings.multi_wdmr_schema.d_selectedSchema = '';
      }
      let screen_mode = {
        'default': this.d_userScreenModes[mode_ind] && this.d_userScreenModes[mode_ind].default ? 1 : 0,
        'd_selectedWdmList': this.d_selectedWdmList,
        'show_last_x_data_list': this.d_searchData.show_last_x_data_list,
        'd_drugMode': this.d_drugMode ? 1 : 0,
        'd_multiWdmrMode': this.d_multiWdmrMode ? 1 : 0,
        'brief_mode': this.brief_mode ? 1 : 0,
        'd_showFastWdmrAddMode': this.d_showFastWdmrAddMode ? 1 : 0,
        'd_isActiveBringingWdmrListWhileMounting': this.d_isActiveBringingWdmrListWhileMounting ? 1 : 0,
        'brief_type': this.brief_type,
        'd_selectedDepartmentList': this.d_selectedDepartmentList,
        'd_timeLineMode': this.d_timeLineMode,
        'd_timeLineType': this.d_timeLineType,
        'd_timeLineYearMonthMode': this.d_timeLineYearMonthMode,
        'view_mode': this.view_mode,
        'd_fastWdmIconList': this.d_fastWdmIconList,
        'd_fastMode': this.d_fastMode,
        'view_type_wisdom_data': this.view_type_wisdom_data,
        'd_showMode': this.d_showMode,
        'd_dateListMode': this.d_dateListMode,
        'label': this.d_newScreenFormatName,
        'wdm_ful_patient_follow': this.wdm.ful.patient_follow,
        'selected_items_data_list': this.tree_list.module_and_model.selected_items_data_list,
        'multi_wdmr_settings': this.d_screenModeWdmrSettings
      };
      let det = false;
      for (let i in this.d_userScreenModes) {
        if (JSON.stringify(this.d_userScreenModes[i]) === JSON.stringify(screen_mode)) {
          det = true;
          if (type === 'new') {
            alert(this.d_userScreenModes[i].label + ' adıyla ekran modu ekli görünmektedir.');
            return;
          } else { // type === 'edit'
            if (parseInt(mode_ind) !== parseInt(i)) {
              alert((parseInt(i) + 1).toString() + ' ekran nolu "' + this.d_userScreenModes[i].label + '" ekran modu zaten mevcut.');
              return;
            }
          }
          break;
        }
      }
      if (!det) {
        if (type === 'new') {
          this.d_userScreenModes.push(screen_mode);
          if (this.d_userScreenModes.length === 1) {
            this.d_userScreenModes[0].default = 1;
          }
        } else {
          this.d_userScreenModes[mode_ind] = screen_mode;
          alert('Güncellendi');
        }
        this.$forceUpdate();
      }
      this.f_saveScreenModeList();
    },
    f_adNewWdmInFastMode: function () {
      this.d_fastWisdomData = {}
    },
    f_addToFastWdmList: function (model) {
      let det = false;
      for (let i in this.d_fastWdmIconList) {
        if (this.d_fastWdmIconList[i].value === model.value) {
          det = true;
          break;
        }
      }
      if (!det) {
        this.d_fastWdmIconList.push(model);
      }
      this.$forceUpdate();
    },
    f_editInThisLocation: function (x_ind, data_type) {
      this.d_editInThisLocation = true;
      let k = false;
      // When we click this function if we have a this.edit_index we have to save the data
      if (this.edit_index !== '') {
        this.f_saveWdmContinue(this.edit_index);
      }
      if (this.edit_index === '' || this.edit_index !== x_ind) {
        k = true;
        this.edit_index = x_ind;
        this.wisdom_op = "edit";
        this.data_type = data_type;
        if (this.d_dateListMode === 'first_new') {
          this.wisdom_data = JSON.parse(JSON.stringify(this.reverse_patient_data_list[x_ind]));
        } else {
          this.wisdom_data = JSON.parse(JSON.stringify(this.patient_data_list[x_ind]));
        }
        let key = this.wisdom_data.reference_wdm.key;
        this.option_data = {};
        this.option_data[data_type] = this.patient_wdm_list[key];
        if (this.view_type_wisdom_data_list.indexOf(x_ind) === -1 && this.view_type_wisdom_data === 'small') {
          this.view_type_wisdom_data_list.push(x_ind);
        }
      } else {
        this.edit_index = '';
        if (this.view_type_wisdom_data_list.indexOf(x_ind) !== -1 && this.view_type_wisdom_data === 'small') {
          this.view_type_wisdom_data_list.splice(this.view_type_wisdom_data_list.indexOf(x_ind), 1);
        }
      }
    },
    f_timeLineMode: function (time_line_type) {
      if (this.d_timeLineMode) {
        this.d_timeLineMode = 0;
      } else {
        if (this.d_drugMode) {
          this.d_drugMode = 0;
          this.d_showMode = 1;
        }
        this.d_timeLineMode = 1;
        this.brief_mode = 0;
        this.d_timeLineType = time_line_type;
        this.f_getPatientRecords();
      }
    },
    f_getInBriefMode: function (brief_type) {
      if (this.user && this.user.settings && this.user.settings.wdm_brief && this.user.settings.wdm_brief.ful && this.user.settings.wdm_brief.ful.patient_follow && this.user.settings.wdm_brief.ful.patient_follow.list && this.user.settings.wdm_brief.ful.patient_follow.list.length > 0) {
        let wdm_list = this.user.settings.wdm_brief.ful.patient_follow.list;
        this.$store.commit('wdm', { 'wdm_list': wdm_list, 'ful': 'patient_follow' });
        this.d_selectedWdmList = [];
        for (let i in wdm_list) {
          if (wdm_list[i].selected) {
            this.d_selectedWdmList.push(wdm_list[i].value);
          }
        }
        // this.d_searchData.show_last_x_data = 'all';
        this.brief_mode = 1;
        this.brief_type = brief_type;
        this.d_timeLineMode = 0;
        this.f_getPatientRecords();
      } else {
        alert('Henüz kayıtlı özet veri listeniz bulunmamaktadır');
      }
    },
    f_createUserBriefList: function () {
      this.user_brief_list = {};
      if (this.user && this.user.settings && this.user.settings.wdm_brief && this.user.settings.wdm_brief.ful && this.user.settings.wdm_brief.ful.patient_follow && this.user.settings.wdm_brief.ful.patient_follow.list && this.user.settings.wdm_brief.ful.patient_follow.list.length > 0) {
        for (let i in this.user.settings.wdm_brief.ful.patient_follow.list) {
          if (this.user.settings.wdm_brief.ful.patient_follow.list[i].brief_list && this.user.settings.wdm_brief.ful.patient_follow.list[i].brief_list.length > 0) {
            let wdm_key = this.user.settings.wdm_brief.ful.patient_follow.list[i].value;
            this.user_brief_list[wdm_key] = this.user.settings.wdm_brief.ful.patient_follow.list[i].brief_list;
          }
        }
      }
      this.wisdom_show_update();
    },
    f_showPatientReportInformationModal: function () {
      this.d_showPatientReportInformationModal = true;
    },
    f_showLabOrRadOrderModal: function (type) {
      if (type === 'laboratory') {
        this.d_showLabOrderModal = true;
      } else if (type === 'radiology') {
        this.d_showRadOrderModal = true;
      }
    },
    f_changeShowMode: function () {
      if (this.d_showMode) {
        this.d_showMode = 0;
      } else {
        this.d_showMode = 1;
      }
    },
    f_motherChildren: function (op) {
      if (this.mother_children[op] === 0) {
        this.mother_children[op] = 1;
      } else {
        this.mother_children[op] = 0;
      }
    },
    f_changeDrugMode: function () {
      if (!this.d_drugMode) {
        this.patient_data_list = [];
        this.d_selectedWdmList = ['wdm10'];
        this.d_searchData.show_last_x_data_list = { 'wdm10': { 'val': 'all' } };
        this.d_timeLineMode = 0;
        this.d_showMode = 0;
        this.d_drugMode = 1;
        if (this.d_wdmr10ShowMode) {
          this.f_getPatientRecords();
        } else {
          this.f_getWdmr11orWdmr12List();
        }
      } else {
        this.d_showWdmr11or12.x = false;
        this.d_drugMode = 0;
        this.d_wdmr11or12List = { 'intermittently_followup': [], 'cycle_followup': [], 'daily_followup': [], 'show_wdmr10': [] };
        this.d_showMode = 1;
      }
    },
    f_removeApproveWdm11: function (wdmr_data) {
      let msg = "Onay işlemini geri almak istiyor musunuz?"
      let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Onay geri alma işlemi hakkında', 'function_name': 'f_removeApproveWdm11Confirmed', 'confirm': false, 'arguments': wdmr_data };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_removeApproveWdm11Confirmed: function (wdmr_data) {
      alert('kurgusu yapılıyor');
      // f_calculateWdm11RemoveApproveLimit fonksiyonundaki if sorgusu detaylandırılacak öncelikle
      // WisdomDataService.remove_approve_wdm11({ 'wdmr_data': wdmr_data })
      //   .then(resp => {
      //     alert('tamaaam');
      //   });
    },
    f_calculateWdm11RemoveApproveLimit: function (wdmr_data) {
      if (wdmr_data.data && wdmr_data.data.approval && wdmr_data.data.approval.doctor && wdmr_data.data.approval.doctor.val && wdmr_data.data.approval.doctor.val.value) {
        if ( /*wdmr_data['data']['general']['model']['val']['value'] === 'intermittently_followup' && */ ['doctor_3', 'doctor_4', 'doctor_7'].indexOf(wdmr_data.data.approval.doctor.val.value) !== -1) {
          return true;
        }
      }
      return false;
    },
    f_calculateWdm10And11And12Delete: function (wdmr_data) {
      if (wdmr_data['type'] === 'wdm10') {
        try {
          if (wdmr_data['data']['status']['status']['val']['value'] === 'active') {
            return false;
          }
        } catch (err) {
          return true;
        }
      }
      if (wdmr_data['type'] === 'wdm12') {
        return false;
      }
      if (wdmr_data['type'] === 'wdm11') {
        if (wdmr_data.data && wdmr_data.data.approval && wdmr_data.data.approval.doctor && wdmr_data.data.approval.doctor.val && wdmr_data.data.approval.doctor.val.value) {
          if (wdmr_data['data']['general']['model']['val']['value'] === 'intermittently_followup' && ['doctor_3', 'doctor_4', 'doctor_7'].indexOf(wdmr_data.data.approval.doctor.val.value) !== -1) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    f_calculateWdm10And11And12Edit: function (wdmr_data) {
      if (wdmr_data['type'] === 'wdm10') {
        try {
          if (wdmr_data['data']['status']['status']['val']['value'] === 'active') {
            return false;
          }
        } catch (err) {
          return true;
        }
      }
      if (wdmr_data['type'] === 'wdm12') {
        return false;
      }
      if (wdmr_data['type'] === 'wdm11') {
        if (wdmr_data.data && wdmr_data.data.approval && wdmr_data.data.approval.doctor && wdmr_data.data.approval.doctor.val && wdmr_data.data.approval.doctor.val.value) {
          if (wdmr_data['data']['general']['model']['val']['value'] === 'intermittently_followup' && ['doctor_3', 'doctor_4', 'doctor_7'].indexOf(wdmr_data.data.approval.doctor.val.value) !== -1) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    f_checkWdmrWebServiceEditable: function (wdmr_data) {
      if (wdmr_data['type'] === 'medical_examination' && wdmr_data['status'] === 'webservice') {
        return false;
      }
      return true;
    },
    f_calculateWdm11ApproveLimit: function (wdmr_data) {
      if (wdmr_data.data && wdmr_data.data.approval && wdmr_data.data.approval.doctor && wdmr_data.data.approval.doctor.val && wdmr_data.data.approval.doctor.val.value) {
        if (['doctor_3', 'doctor_4', 'doctor_7'].indexOf(wdmr_data.data.approval.doctor.val.value) === -1) {
          return true;
        }
      } else {
        return true;
      }
      return false;
    },
    f_calculateWdm11ContinueLimit: function (wdmr_data) {
      if (wdmr_data.data && wdmr_data.data.approval && wdmr_data.data.approval.doctor && wdmr_data.data.approval.doctor.val && wdmr_data.data.approval.doctor.val.value) {
        if (wdmr_data['data']['general']['model']['val']['value'] === 'intermittently_followup' && ['doctor_3', 'doctor_4', 'doctor_7'].indexOf(wdmr_data.data.approval.doctor.val.value) !== -1) {
          return true;
        }
      }
      return false;
    },
    f_approveWdm11: function (wdmr_data, approve_type, approve_detail) {
      WisdomDataService.approve_wdm11({ 'wdmr_data': wdmr_data, 'approve_detail': approve_detail })
        .then(resp => {
          this.f_showTrueFalse();
          if (resp.data.status === 'success') {
            if (wdmr_data['data']['general']['model']['val']['value'] === 'intermittently_followup') {
              this.f_continueWdm11(wdmr_data);
            }
          } else {
            alert('error:', );
          }
        });
    },
    f_continueWdm11: function (wdmr_data) {
      let msg = "Tedaviye devam etmek istiyor musunuz?"
      let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Tedavi Onay İşlemi Hakkında', 'function_name': 'f_continueWdm11Confirmed', 'confirm': false, 'arguments': wdmr_data };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_continueWdm11Confirmed: function (wdmr_data) {
      WisdomDataService.add_new_clone_wdm11({ 'wdmr_data': wdmr_data })
        .then(resp => {
          alert('tamaaam');
        });
    },
    f_showForceGetHimsData: function () {
      this.d_forceGetHimsDataOnlyDepartments = [];
      if (this.d_allDepartmentsList.length === 0) {
        let data = {}
        data.hospital_group = this.user.hospital_group;
        data.hospital_id = this.user.hospital_id;
        data.wtl_type = 'department';
        HospitalService.get_hospital_wtl_as_select(data)
          .then(resp => {
            // console.log(resp.data.result);
            this.d_allDepartmentsList = resp.data.result;
            this.d_allDepartmentsList.sort(function (a, b) {
              var textA = a.label.toUpperCase();
              var textB = b.label.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            // this.d_allDepartmentsList = resp.data.result.sort((a, b) => {
            //     return a['label'] < b['label']
            // })
            this.d_showForceGetHimsData = true;
          });
      } else {
        this.d_showForceGetHimsData = true;
      }
    },
    f_selectAllDepartmentsForForceGet: function () {
      this.d_forceGetHimsDataOnlyDepartments = [];
      for (let i in this.d_allDepartmentsList) {
        this.d_forceGetHimsDataOnlyDepartments.push(this.d_allDepartmentsList[i].value);
      }
    },
    f_addRemoveDepartmentToForceList: function (dep_value) {
      let dep_ind = this.d_forceGetHimsDataOnlyDepartments.indexOf(dep_value);
      if (dep_ind === -1) {
        this.d_forceGetHimsDataOnlyDepartments.push(dep_value);
      } else {
        this.d_forceGetHimsDataOnlyDepartments.splice(dep_ind, 1);
      }
    },
    f_forceGetHimsData: function (patient_id, data_type) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veriler getiriliyor.' } });

      let data = { 'patient_id': patient_id, 'data_type': data_type, 'onlyDepartments': this.d_forceGetHimsDataOnlyDepartments };
      PatientService.force_get_hims_data(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.d_showForceGetHimsData = false;
          this.f_getPatientRecords();
        });
    },
    f_userHasPermissionToEditThisModel: function (department_value, data_type, hospital_group, hospital_id) {
      if (this.user.hospital_group !== hospital_group || this.user.hospital_id !== hospital_id) {
        return false;
      }
      if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1 || this.user.permissions_result.indexOf('administrator_global-wdmr-edit') !== -1)) {
        return true;
      }
      for (let i in this.user.position_department_title) {
        if (this.user.position_department_title[i].active && this.user.position_department_title[i].department.value === department_value && this.user.position_department_title[i].wisdom_data_model && this.user.position_department_title[i].wisdom_data_model[data_type] && this.user.position_department_title[i].wisdom_data_model[data_type].permission && this.user.position_department_title[i].wisdom_data_model[data_type].permission.indexOf('record_add_edit') !== -1) {
          return true;
        }
      }
      return false;
    },
    f_userHasPermissionToAddThisModel: function (data_type) {
      if (!this.patient.hospitals || !this.patient.hospitals[this.user.hospital_group] || (this.patient.hospitals[this.user.hospital_group] && !this.patient.hospitals[this.user.hospital_group][this.user.hospital_id])) {
        return false;
      }
      if (this.user.permissions_result && this.user.permissions_result.indexOf('administrator') !== -1) {
        return true;
      }
      for (let i in this.user.position_department_title) {
        if (this.user.position_department_title[i].active && this.user.position_department_title[i].wisdom_data_model && this.user.position_department_title[i].wisdom_data_model[data_type] && this.user.position_department_title[i].wisdom_data_model[data_type].permission && this.user.position_department_title[i].wisdom_data_model[data_type].permission.indexOf('record_add_edit') !== -1) {
          return true;
        }
      }
      return false;
    },
    f_calculateDepartmentName: function (department_value) {
      for (let i in this.st_department.ful.patient_follow) {
        if (department_value === this.st_department.ful.patient_follow[i].value) {
          return this.st_department.ful.patient_follow[i].label;
        }
      }
    },
    f_showTrueFalse: function () {
      this.d_showPatientDataRecords = false;
      setTimeout(function () {
        this.d_showPatientDataRecords = true;
      }.bind(this), 1000)
    },
    f_selectAllModels: function () {
      if (this.d_selectedWdmList.length > 0) {
        this.d_selectedWdmList = [];
      } else {
        for (let i in this.wdm.ful.patient_follow) {
          this.d_selectedWdmList.push(this.wdm.ful.patient_follow[i].value);
        }
      }
    },
    f_selectAllDepartments: function () {
      if (this.d_selectedDepartmentList.length > 0) {
        this.d_selectedDepartmentList = [];
      } else {
        for (let i in this.st_department.ful.patient_follow) {
          this.d_selectedDepartmentList.push(this.st_department.ful.patient_follow[i].value);
        }
      }
    },
    f_sortWithSelectedUpper: function (data_list, selected_list) {
      let x = [];
      let y = [];
      for (let i in data_list) {
        if (selected_list.indexOf(data_list[i].value) !== -1) {
          x.push(data_list[i]);
        } else {
          y.push(data_list[i]);
        }
      }
      return x.concat(y);
    },
    f_CalculateName: function (data_type) {
      for (let i in this.wdm.ful.patient_follow) {
        if (this.wdm.ful.patient_follow[i].value === data_type) {
          return this.wdm.ful.patient_follow[i].label;
        }
      }
      return data_type;
    },
    f_getPatientRecords: function () {
      if (this.d_showScreenSettings) {
        return;
      }
      this.patient_data_list = [];
      if (this.d_selectedWdmList.length === 0) {
        alert('Lütfen veri modeli seçimi yapınız');
        return;
      }
      if (this.d_selectedDepartmentList.length === 0) {
        alert('Lütfen departman seçimi yapınız');
        return;
      }
      for (let i in this.d_searchData.show_last_x_data_list) {
        if (this.d_searchData.show_last_x_data_list[i].val === 'date_interval') {
          if (!this.f_calculateDayDiff(this.d_searchData.show_last_x_data_list[i].started_at, this.d_searchData.show_last_x_data_list[i].ending_at)) {
            alert('lütfen tarih aralığı başlangıç ve bitiş değerlerini düzeltiniz');
            return;
          }
        }
      }
      let department_filter = { 'filter_with_department': [], 'all_departments': [], 'not_eligible_for_department_relation': [] };
      if (!this.d_drugMode) {
        for (let i in this.wdm.ful.patient_follow) {
          if (this.d_selectedWdmList.indexOf(this.wdm.ful.patient_follow[i].value) !== -1) {
            if (this.wdm.ful.patient_follow[i].department_filter_mode === 'filter_with_department') {
              department_filter.filter_with_department.push(this.wdm.ful.patient_follow[i].value);
            } else if (this.wdm.ful.patient_follow[i].department_filter_mode === 'all_departments') {
              department_filter.all_departments.push(this.wdm.ful.patient_follow[i].value);
            } else if (this.wdm.ful.patient_follow[i].department_filter_mode === 'not_eligible_for_department_relation') {
              department_filter.not_eligible_for_department_relation.push(this.wdm.ful.patient_follow[i].value);
            }
          }
        }
      } else {
        department_filter.all_departments.push('wdm10');
      }
      this.d_searchData.department_filter = department_filter;
      this.d_searchData.patient_id = this.patient.id;
      this.d_searchData.department_list = this.d_selectedDepartmentList;
      if (this.brief_mode) {
        this.d_searchData.brief_type = this.brief_type;
      } else {
        if (this.d_searchData.brief_type) {
          delete this.d_searchData.brief_type;
        }
      }
      if (this.d_timeLineMode) {
        this.d_searchData.time_line_type = this.d_timeLineType;
        this.d_searchData.year_month_mode = this.d_timeLineYearMonthMode;
      } else {
        if (this.d_searchData.time_line_type) {
          delete this.d_searchData.time_line_type;
          delete this.d_searchData.year_month_mode;
        }
      }
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veriler getiriliyor.' } });
      this.d_loadingSentence.f_getPatientRecords.show = true;
      if (this.d_searchData.patient_id) {
        WisdomDataService.get_patient_wdmr(this.d_searchData)
          .then(resp => {
            this.d_loadingSentence.f_getPatientRecords.show = false;
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.f_showTrueFalse();
            if (resp.data.status === 'success') {
              this.patient_data_list = resp.data.result.wdmr_list;
              this.patient_wdm_list = resp.data.result.wdm_list;
              if (this.d_timeLineMode && resp.data.result.left_to_right) {
                this.d_timeLineLeftToRight = resp.data.result.left_to_right;
              }
              if (this.d_drugMode) {
                this.d_wdmr10ShowMode = true;
                this.d_showWdmr11or12.x = true;
                this.f_motherChildren('update_patient_wdmr');
              }
            } else {
              alert('error:', )
            }
          });
      }
    },
    f_getMyFavoriteWdmList: function () {
      this.$store.commit('wdm', { 'wdm_list': this.user.settings.wdm.ful['patient_follow'].list, 'ful': 'patient_follow' });
      this.d_selectedWdmList = [];
      for (let i in this.user.settings.wdm.ful['patient_follow'].list) {
        if (this.user.settings.wdm.ful['patient_follow'].list[i].selected) {
          this.d_selectedWdmList.push(this.user.settings.wdm.ful['patient_follow'].list[i].value);
        }
      }
    },
    f_saveBriefParameters: function () {
      let user_data = JSON.parse(localStorage.getItem('user'));
      ClsUserSettings.free_structure(user_data);
      if (!user_data.settings.wdm_brief) {
        user_data.settings.wdm_brief = { 'ful': {} };
      }
      if (!user_data.settings.wdm_brief.ful.patient_follow) {
        user_data.settings.wdm_brief.ful['patient_follow'] = { 'list': [] };
      }
      user_data.settings.wdm_brief.ful['patient_follow'].list = [];
      for (let wdm_key in this.d_wdmParameters.brief_list) {
        if (this.d_wdmParameters.brief_list[wdm_key].length > 0) {
          let x = {};
          for (let i in this.d_wdmParameters.user_selected_brief_wdm_list) {
            if (this.d_wdmParameters.user_selected_brief_wdm_list[i].value === wdm_key) {
              x = this.d_wdmParameters.user_selected_brief_wdm_list[i];
              x.brief_list = this.d_wdmParameters.brief_list[wdm_key];
              user_data.settings.wdm_brief.ful['patient_follow'].list = user_data.settings.wdm_brief.ful['patient_follow'].list.concat(x);
              break;
            }
          }
        }
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı ayarlarınız güncelleniyor.' } });
      let data = { 'ful': 'patient_follow', 'wdm_list': user_data.settings.wdm_brief.ful['patient_follow'].list, 'username': user_data.username };
      UserService.saveWdmBriefList(data)
        .then(resp => {
          this.d_showWdmParameterManageModal = false;
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12713'));
            this.user = JSON.parse(JSON.stringify(resp.data.result));
            localStorage.setItem('user', JSON.stringify(this.user));
            this.f_createUserBriefList();
          } else {
            alert(this.$t('wdm16.12714'), resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_getMyBriefWdmList: function () {
      if (this.user && this.user.settings && this.user.settings.wdm_brief && this.user.settings.wdm_brief.ful && this.user.settings.wdm_brief.ful.patient_follow && this.user.settings.wdm_brief.ful.patient_follow.list && this.user.settings.wdm_brief.ful.patient_follow.list.length > 0) {
        let wdm_list = this.user.settings.wdm_brief.ful.patient_follow.list;
        this.$store.commit('wdm', { 'wdm_list': wdm_list, 'ful': 'patient_follow' });
        this.d_selectedWdmList = [];
        for (let i in wdm_list) {
          if (wdm_list[i].selected) {
            this.d_selectedWdmList.push(wdm_list[i].value);
          }
        }
      } else {
        alert('Henüz kayıtlı özet veri listeniz bulunmamaktadır');
      }
    },
    f_myBriefWdmParameterListSettings: function () {
      let user_wdm_list = [];
      if (this.user && this.user.settings && this.user.settings.wdm_brief && this.user.settings.wdm_brief.ful && this.user.settings.wdm_brief.ful.patient_follow && this.user.settings.wdm_brief.ful.patient_follow.list && this.user.settings.wdm_brief.ful.patient_follow.list.length > 0) {
        user_wdm_list = this.user.settings.wdm_brief.ful.patient_follow.list;
      }
      // console.log(user_wdm_list);
      let wdm_list = [];
      let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
      for (let type in wdm_version) {
        // type = hospital, user, wisdom
        for (let w in wdm_version[type]['all']) {
          let wdm_feature = wdm_version[type]['all'][w];
          // console.log(wdm_feature);
          if (wdm_feature['permission'] && (wdm_feature['permission'].indexOf('record_add_edit') !== -1 || wdm_feature['permission'].indexOf('record_view') !== -1)) {
            for (let v in wdm_feature['list']) {
              let x = {
                'label': wdm_feature['name']['label'] + '(' + type + ', ' + w + ', ' + 'version:' + wdm_feature['list'][v]['version'] + ', ' + ')',
                'value': wdm_feature['list'][v]['key'],
                'version': wdm_feature['list'][v]['version'],
                'location_type': type,
                'wdm_type': w
              }
              for (let uwl in user_wdm_list) {
                if (wdm_feature['list'][v]['key'] === user_wdm_list[uwl]['value']) {
                  x = user_wdm_list[uwl];
                }
              }
              wdm_list.push(x);
            }
          }
        }
      }
      this.d_wdmParameters = {
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': wdm_list.sort(this.f_sortByComparingArrayObjectItems),
        'option_data': {},
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 }
      };
    },
    f_sortByComparingArrayObjectItems: function (a, b) {
      // console.log(a);
      // Use toUpperCase() to ignore character casing
      /*
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      */
      let comparison = 0;
      if (a['label'] > b['label']) {
        comparison = 1;
      } else if (a['label'] < b['label']) {
        comparison = -1;
      }
      return comparison;
    },
    f_getMyFavoriteDepartmentList: function () {
      this.$store.commit('department', { 'department_list': this.user.settings.department.ful['patient_follow'].list, 'ful': 'patient_follow' });
      this.d_selectedDepartmentList = [];
      for (let i in this.user.settings.department.ful['patient_follow'].list) {
        if (this.user.settings.department.ful['patient_follow'].list[i].selected) {
          this.d_selectedDepartmentList.push(this.user.settings.department.ful['patient_follow'].list[i].value);
        }
      }
    },
    f_makeFavoriteOfThisDepartmentList: function () {
      if (this.st_department && this.st_department.ful && this.st_department.ful.patient_follow) {
        let department_list = [];
        let user_data = JSON.parse(localStorage.getItem('user'));
        ClsUserSettings.free_structure(user_data);
        if (!user_data.settings.department) {
          user_data.settings.department = { 'ful': {} };
        }
        if (!user_data.settings.department.ful.patient_follow) {
          user_data.settings.department.ful['patient_follow'] = { 'list': [] };
        }
        user_data.settings.department.ful['patient_follow'].list = [];
        for (let i in this.st_department.ful.patient_follow) {
          let x = {
            'label': this.st_department.ful.patient_follow[i].label,
            'value': this.st_department.ful.patient_follow[i].value,
            'selected': 0
          };
          if (this.d_selectedDepartmentList.indexOf(this.st_department.ful.patient_follow[i].value) !== -1) {
            x.selected = 1;
          }
          department_list.push(x);
        }
        user_data.settings.department.ful['patient_follow'].list = department_list;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı ayarlarınız güncelleniyor.' } });
        let data = { 'ful': 'patient_follow', 'department_list': department_list, 'username': user_data.username };
        UserService.saveDepartmentFavoriteList(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              localStorage.setItem('user', JSON.stringify(user_data));
              this.user = user_data;
              alert(this.$t('wdm16.12713'));
            } else {
              alert(this.$t('wdm16.12714'), resp.data.message);
            }
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      } else {
        alert('Seçili veri modeliniz bulunmamaktadır');
      }
    },
    f_makeFavoriteOfThisWdmList: function () {
      if (this.wdm && this.wdm.ful && this.wdm.ful.patient_follow) {
        let user_data = JSON.parse(localStorage.getItem('user'));
        ClsUserSettings.free_structure(user_data);
        let wdm_list = [];
        if (!user_data.settings.wdm) {
          user_data.settings.wdm = { 'ful': {} };
        }
        if (!user_data.settings.wdm.ful.patient_follow) {
          user_data.settings.wdm.ful['patient_follow'] = { 'list': [] };
        }
        user_data.settings.wdm.ful['patient_follow'].list = [];
        for (let i in this.wdm.ful.patient_follow) {
          let x = {
            'label': this.wdm.ful.patient_follow[i].label,
            'value': this.wdm.ful.patient_follow[i].value,
            'selected': 0,
            'record_count': this.wdm.ful.patient_follow[i].record_count,
            'department_filter_mode': this.wdm.ful.patient_follow[i].department_filter_mode,
            'send_to_hims_protocol': this.wdm.ful.patient_follow[i].send_to_hims_protocol
          };
          if (this.d_selectedWdmList.indexOf(this.wdm.ful.patient_follow[i].value) !== -1) {
            x.selected = 1;
          }
          wdm_list.push(x);
        }
        user_data.settings.wdm.ful['patient_follow'].list = wdm_list;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı ayarlarınız güncelleniyor.' } });
        let data = { 'ful': 'patient_follow', 'wdm_list': wdm_list, 'username': user_data.username };
        UserService.saveWdmFavoriteList(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              localStorage.setItem('user', JSON.stringify(user_data));
              this.user = user_data;
              alert(this.$t('wdm16.12713'));
            } else {
              alert(this.$t('wdm16.12714'), resp.data.message);
            }
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      } else {
        alert('Seçili veri modeliniz bulunmamaktadır');
      }
    },
    f_makeBriefOfThisWdmList: function () {
      if (this.wdm && this.wdm.ful && this.wdm.ful.patient_follow) {
        let wdm_list = [];
        let user_data = JSON.parse(localStorage.getItem('user'));
        ClsUserSettings.free_structure(user_data);
        if (!user_data.settings.wdm_brief) {
          user_data.settings.wdm_brief = { 'ful': {} };
        }
        if (!user_data.settings.wdm_brief.ful.patient_follow) {
          user_data.settings.wdm_brief.ful['patient_follow'] = { 'list': [] };
        }
        for (let i in this.wdm.ful.patient_follow) {
          let x = {
            'label': this.wdm.ful.patient_follow[i].label,
            'value': this.wdm.ful.patient_follow[i].value,
            'selected': 0,
            'record_count': this.wdm.ful.patient_follow[i].record_count,
            'department_filter_mode': this.wdm.ful.patient_follow[i].department_filter_mode,
            'send_to_hims_protocol': this.wdm.ful.patient_follow[i].send_to_hims_protocol
          };
          if (this.d_selectedWdmList.indexOf(this.wdm.ful.patient_follow[i].value) !== -1) {
            x.selected = 1;
          }
          wdm_list.push(x);
        }
        let new_brief_list = [];
        for (let i in wdm_list) {
          let prepare_wdm_brief = wdm_list[i];
          // Below we are looking for the model. Is that model has brief_list before added.
          for (let k in user_data.settings.wdm_brief.ful['patient_follow'].list) {
            if (wdm_list[i].value === user_data.settings.wdm_brief.ful['patient_follow'].list[k].value && user_data.settings.wdm_brief.ful['patient_follow'].list[k]['brief_list']) {
              prepare_wdm_brief['brief_list'] = user_data.settings.wdm_brief.ful['patient_follow'].list[k]['brief_list'];
              break;
            }
          }
          new_brief_list.push(prepare_wdm_brief);
        }
        user_data.settings.wdm_brief.ful['patient_follow'].list = new_brief_list;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı ayarlarınız güncelleniyor.' } });
        let data = { 'ful': 'patient_follow', 'wdm_list': wdm_list, 'username': user_data.username };
        UserService.saveWdmBriefList(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              localStorage.setItem('user', JSON.stringify(user_data));
              this.user = user_data;
              alert(this.$t('wdm16.12713'));
            } else {
              alert(this.$t('wdm16.12714'), resp.data.message);
            }
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      } else {
        alert('Seçili veri modeliniz bulunmamaktadır');
      }
    },
    f_calculateDayDiff: function (first_date, second_date) {
      // birinci tarih ikinci tarihe eşit ya da geride olması durumunda true dönüyor.
      if (first_date === second_date) {
        return true;
      } else {
        if (DateFunctions.day_dif(first_date, second_date) > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    f_addRemoveDepartment: function (department_value) {
      if (this.d_selectedDepartmentList.indexOf(department_value) !== -1) {
        this.d_selectedDepartmentList.splice(this.d_selectedDepartmentList.indexOf(department_value), 1);
      } else {
        this.d_selectedDepartmentList.push(department_value);
      }
      this.$forceUpdate();
    },
    f_addRemoveWdm: function (model) {
      if (this.d_selectedWdmList.indexOf(model.value) !== -1) {
        this.d_selectedWdmList.splice(this.d_selectedWdmList.indexOf(model.value), 1);
      } else {
        this.d_selectedWdmList.push(model.value);
      }
      this.$forceUpdate();
    },
    f_calculateDepartmentClass: function (department_value) {
      if (this.d_selectedDepartmentList.indexOf(department_value) !== -1) {
        return 's_rowSelected';
      } else {
        return 's_rowNotSelected';
      }
    },
    f_calculateWdmClass: function (model_value) {
      if (this.d_selectedWdmList.indexOf(model_value) !== -1) {
        return 's_rowSelected';
      } else {
        return 's_rowNotSelected';
      }
    },
    TypeWisdomData: function () {
      if (this.view_type_wisdom_data === 'long') {
        this.view_type_wisdom_data = 'small';
      } else {
        this.view_type_wisdom_data = 'long';
        this.view_type_wisdom_data_list = [];
      }
    },
    CalculateShowWisdomDataClassOne: function (x_ind) {
      if (this.view_type_wisdom_data_list.indexOf(x_ind) !== -1) {
        this.view_type_wisdom_data_list.splice(this.view_type_wisdom_data_list.indexOf(x_ind), 1);
      } else {
        this.view_type_wisdom_data_list.push(x_ind);
      }
      this.$forceUpdate();
    },
    CalculateShowWisdomDataClass: function (x_ind) {
      if (this.view_type_wisdom_data_list.indexOf(x_ind) !== -1) {
        if (this.view_mode === 'wdm_specific') {
          return 'specific-wisdom-data';
        }
        // console.log(x_ind);
        return 'long-wisdom-data';
      } else {
        if (this.view_mode === 'wdm_specific') {
          return 'specific-wisdom-data';
        }
        return this.view_type_wisdom_data === 'long' ? 'long-wisdom-data' : 'small-wisdom-data';
      }
    },
    wisdom_show_update: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      // this.f_showTrueFalse();
      this.$forceUpdate();
    },
    f_wisdomUpdate: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      this.$forceUpdate();
    },
    f_deleteWdmr: function (i_uuid, type) {
      let msg = "Silmek istediğinize emin misiniz?"
      let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Tedavi Onay İşlemi Hakkında', 'function_name': 'f_deleteWdmrComplete', 'confirm': false, 'arguments': [i_uuid, type] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_deleteWdmrComplete: function (arg = []) {
      // console.log('arg', arg);
      let i_uuid, type = '';
      i_uuid = arg[0];
      type = arg[1];
      WisdomDataService.delete(this.patient.id, i_uuid, type).then(resp => {
        let msg = '';
        if (resp.data.status === "success") {
          let list_ind = '';
          for (let i in this.patient_data_list) {
            if (this.patient_data_list[i].id === i_uuid) {
              list_ind = i;
              break
            }
          }
          this.patient_data_list.splice(list_ind, 1);
          this.wisdom_show_update();
          this.f_showTrueFalse();
          msg = 'Veri modeli silme işlemi başarıyla tamamlandı.';
        } else {
          let msg = resp.data.message;
        }
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Veri modeli silme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      });
    },
    SendThisDataToWebserviceInspection: function (SendingWisdomData, FirstPrepare, DataType) {
      let protocolList = this.FirstControlOpenProtocollist(this.patient, this.hospitalData);
      if (protocolList.length > 0) {
        if (!this.patient.diagnosis || this.patient.diagnosis.length === 0) {
          let msg = 'Hastaya ekli tanı tespit edilemedi.';
          let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Verinin webservis üzerinden protokol muayene bilgilerine gönderilme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        } else {
          if (this.patient.hospital_group === 'MLPCARE') {
            let webserviceData = { 'protocol_list': [], 'hospitalGroup': this.patient.hospital_group, 'hospitalCode': this.patient.hospital_id, 'sending_json_data': {}, 'sending': 1, 'SendingWisdomData': SendingWisdomData, 'FirstPrepare': FirstPrepare, 'DataType': DataType };
            webserviceData.sending_json_data = JSON.parse(JSON.stringify(this.WebserviceProtocolInsuranceData['MLPCARE']));
            webserviceData.sending_json_data.symptom_start_date = new Date().toISOString();
            webserviceData.sending_json_data.pre_diagnosis = this.GetPatientDiagnosisListWithComma(this.patient);
            webserviceData.sending_json_data.main_diagnosis = this.GetPatientDiagnosisListWithComma(this.patient);
            webserviceData.protocol_list = protocolList;
            // console.log('webserviceData', webserviceData);
            WebService.SendWisdomDataToProtocolInspection(webserviceData).
            then(resp => {
              // console.log(resp.data);
            });
          }
        }
      } else {
        let msg = 'Hastanın açık protokolü bulunmamaktadır.';
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Verinin webservis üzerinden protokol muayene bilgilerine gönderilme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    GetPatientDiagnosisListWithComma: function (patientData) {
      let diagnosisList = [];
      for (let i in patientData.diagnosis) {
        diagnosisList.push(patientData.diagnosis[i].value);
      }
      return diagnosisList.join(',');
    },
    FirstControlOpenProtocollist: function (patientData, hospitalData) {
      let protocolList = [];
      if (patientData.hospitals && patientData.hospitals[this.hospitalData.hospital_group] && patientData.hospitals[this.hospitalData.hospital_group][this.hospitalData.hospital_id] && patientData.hospitals[this.hospitalData.hospital_group][this.hospitalData.hospital_id].webservice === 1 && patientData.open_protocol_list && patientData.open_protocol_list.result) {
        for (let i in patientData.open_protocol_list.result) {
          let protocol_data = patientData.open_protocol_list.result[i];
          // if ([1, 2].indexOf(protocol_data.state) !== -1 && protocol_data.branch_id === 103) {
          protocolList.push(protocol_data);
          // }
        }
      }
      return protocolList;
    },
    f_showWdmrReport: function (x_ind) {
      this.d_wdmrReportData.wisdom_data = [];
      this.d_wdmrReportData.option_data = {};
      let key = '';
      if (this.d_dateListMode === 'first_new') {
        key = this.reverse_patient_data_list[x_ind].reference_wdm.key;
        this.d_wdmrReportData.wisdom_data.push(this.reverse_patient_data_list[x_ind]);
      } else {
        key = this.patient_data_list[x_ind].reference_wdm.key;
        this.d_wdmrReportData.wisdom_data.push(this.patient_data_list[x_ind]);
      }
      this.d_wdmrReportData.option_data[key] = this.patient_wdm_list[key];
      this.d_showWdmrReportingModal = true;
    },
    f_editWdmr: function (x_ind, data_type) {
      this.d_editInThisLocation = false;
      this.edit_index = x_ind;
      this.wisdom_op = "edit";
      this.data_type = data_type;
      if (this.d_dateListMode === 'first_new') {
        this.wisdom_data = JSON.parse(JSON.stringify(this.reverse_patient_data_list[x_ind]));
      } else {
        this.wisdom_data = JSON.parse(JSON.stringify(this.patient_data_list[x_ind]));
      }
      let key = this.wisdom_data.reference_wdm.key;
      this.option_data = {};
      this.option_data[data_type] = this.patient_wdm_list[key];
      this.d_showWdmModal = true;
    },
    f_cloneWdmr (x_ind, data_type) {
      this.d_editInThisLocation = false;
      this.wisdom_op = "edit";
      this.data_type = data_type;
      if (this.d_dateListMode === 'first_new') {
        this.wisdom_data = JSON.parse(JSON.stringify(this.reverse_patient_data_list[x_ind]));
      } else {
        this.wisdom_data = JSON.parse(JSON.stringify(this.patient_data_list[x_ind]));
      }
      if (this.wisdom_data.revision) {
        delete this.wisdom_data.revision;
      }
      this.wisdom_data.status = 'new';
      this.wisdom_data.id = 'new';
      if (this.wisdom_data.date) {
        this.wisdom_data.date = '';
      }
      if (this.wisdom_data.ending_date) {
        this.wisdom_data.ending_date = '';
      }
      if (this.wisdom_data.real_created_by) {
        this.$delete(this.wisdom_data, 'real_created_by');
      }
      let key = this.wisdom_data.reference_wdm.key;
      this.option_data = {};
      this.option_data[data_type] = this.patient_wdm_list[key];
      this.d_showWdmModal = true;
    },
    f_saveWdmWorkingonnnnnnnnnn: function () {
      ClsWdmrSave.save(this.wisdom_data, this.option_data[this.data_type]);
      // let wdmr_name, wdmr_require_alerts, only_one_param_is_filled = ClsWdmrSave.save(this.wisdom_data, this.option_data[this.data_type]);
    },
    f_saveWdm: function (mode = '') {
      // console.log(this.wisdom_data);
      // console.log(this.d_selectedAnatomySchemaList);
      if (mode === 'fast_mode') {
        this.wisdom_data = this.d_fastMode.wisdom_data;
        this.data_type = this.d_fastMode.data_type;
        this.option_data = this.d_fastMode.option_data;
      }
      if (this.option_data[this.wisdom_data.type].date_ability && this.option_data[this.wisdom_data.type].date_ability === 'yes') {
        if (!this.wisdom_data.date) {
          let modal_data = { 'type': 'alert', 'text': "Lütfen tarih giriniz", 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }

      if (this.d_selectedAnatomySchemaList.length > 0) {

        let data = {
          'list': this.d_selectedAnatomySchemaList
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Anatomi birleştirme işlemi başlatıldı. Lütfen bekleyiniz.' } });
        SchemaService.get_schema_data_list(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            if (resp.data.status === 'success') {
              let anatomy_schema_list = resp.data.result;
              this.d_selectedAnatomySchemaList = [];
              this.mother_children.d_showAnatomyData = false;
              for (let i in anatomy_schema_list) {
                if (!this.wisdom_data.data.anatomy) {
                  this.wisdom_data.data.anatomy = JSON.parse(JSON.stringify(anatomy_schema_list[i].schema_data));
                } else {
                  for (let k in anatomy_schema_list[i].schema_data) {
                    if (!this.wisdom_data.data.anatomy[k]) {
                      this.wisdom_data.data.anatomy[k] = anatomy_schema_list[i].schema_data[k];
                    }
                  }
                }
              }
              console.log
              setTimeout(function () {
                this.mother_children.d_showAnatomyData = true;
                this.f_continueSaveWdmAfterCombine();
                this.$forceUpdate();
              }.bind(this), 50)
            } else {
              this.f_continueSaveWdmAfterCombine();
              //
            }
          });

      } else {
        this.f_continueSaveWdmAfterCombine();
      }
    },
    f_continueSaveWdmAfterCombine: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['require', 'name']);
      // console.log('return_data: ', return_data);
      // return;
      let wdmr_name = return_data.name;
      this.wisdom_data['label'] = wdmr_name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      // Bir wdmr kaydı yapabilmek için en az bir parametre dolu olması gerekiyor.
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      // wdmr kaydı sırasında tüm wdm parametreleri için require kontrolü yapıyor. İlgili parametrede val değeri oluştuysa zaten oraya girilmiş oluyor. İçine girilipte (wisdom-param componenti) dolu olmayan tüm parametreleri kontrol ediyoruz. Örneğin er ihd testini ekledi. Fakat pozitif / negatif değerini doldurmadıysa geçemiyor.
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['require', 'name', 'delete']);
      this.wisdom_data = JSON.parse(JSON.stringify(return_data.wdmr));
      this.wisdom_data['label'] = wdmr_name;
      this.patient_open_protocol_list = [];
      if (this.patient.open_protocol_list && this.patient.open_protocol_list.result && this.patient.open_protocol_list.result.length > 0) {
        this.patient_open_protocol_list = this.patient.open_protocol_list.result;
      }
      if (this.option_data[this.data_type].send_to_hims_protocol && this.option_data[this.data_type].send_to_hims_protocol === 'yes' && this.user.permissions_result && this.user.permissions_result.indexOf('hims_send-wdmr-while-recording') !== -1 && this.patient_open_protocol_list.length > 0) {
        this.prepared_hims_data = {};
        this.hims_protocol_list = {};
        let user_has_permission_to_send_hims = false;
        for (let i in this.user.position_department_title) {
          if (this.user.position_department_title[i].active) {
            for (let k in this.user.clinical_branch) {
              // aşağıda kullanıcının şu anda aktif olduğu departmana ait hbys idsinin kullanıcının clinical branşında eşleniği varsa
              // yani bu departman kullanıcısının bir de bu departmanda başvuru protokolüne veri kaydedebilme yetkisi varsa
              if (this.user.position_department_title[i].department.hims_branch_id === this.user.clinical_branch[k].hims_branch_id) {
                user_has_permission_to_send_hims = true;
                let data = { 'wisdom_data': this.wisdom_data };
                WisdomDataService.prepare_hims_protocol_inspection_data(this.patient.id, data)
                  .then(resp => {
                    if (resp.data.status === 'success') {
                      this.prepared_hims_data = resp.data.result;
                      if (this.prepared_hims_data.type === 'diagnosis') {
                        let diag_data = resp.data.result.data.general.diagnosis.val;
                        diag_data.id = resp.data.result.id;
                        diag_data.label = diag_data.label + ' - ' + resp.data.result.date;
                        this.patient.diagnosis.push(diag_data);
                        store.commit('patient_diagnosis_list', this.patient.diagnosis);
                      }
                    } else {
                      alert('error ', resp.data.message);
                    }
                    this.d_showHimsProtocols = true;
                  });
              }
            }
          }
        }
        if (!user_has_permission_to_send_hims) {
          this.f_saveWdmContinue();
        }
      } else {
        this.f_saveWdmContinue();
      }
    },
    f_protocolManuelEditControl: function (protocol_data) {
      // kullanıcı bir kez manuel edit ettikten sonra artık son bilgiyi ekleme işi iptal oluyor.
      if (!protocol_data.manuel_edit) {
        for (let i in protocol_data.param_list) {
          if (protocol_data.param_list[i].type === 'many') {
            if (protocol_data.param_list[i]['text_list'].length > 0) {
              let last_ind = protocol_data.param_list[i]['text_list'].length - 1;
              // null değerler olabildiğinden önce onları boş text haline getiriyoruz aşağıda.
              if (!protocol_data[i]) {
                protocol_data[i] = '';
                if (i === 'main_diagnosis' || i === 'pre_diagnosis') {
                  protocol_data[i] = protocol_data.param_list[i]['text_list'][last_ind].icd_code;
                } else {
                  protocol_data[i] = protocol_data.param_list[i]['text_list'][last_ind].txt;
                }
              } else { // ilgili alan bir kez doldurulmuş ise
                if (i === 'main_diagnosis' || i === 'pre_diagnosis') {
                  if (protocol_data[i].indexOf(protocol_data.param_list[i]['text_list'][last_ind].icd_code) === -1) {
                    protocol_data[i] += ',' + protocol_data.param_list[i]['text_list'][last_ind].icd_code;
                  }
                } else {
                  if (protocol_data[i].indexOf(protocol_data.param_list[i]['text_list'][last_ind].txt) === -1) {
                    protocol_data[i] += ' ' + protocol_data.param_list[i]['text_list'][last_ind].txt;
                  }
                }
              }
              protocol_data[i] = protocol_data[i].replace(/\n/g, '');
            } else {
              if (!protocol_data[i]) {
                protocol_data[i] = '';
              }
            }
          } else if (protocol_data.param_list[i].type === 'one') {
            if (protocol_data.param_list[i]['text_list'].length > 0) {
              let last_ind = protocol_data.param_list[i]['text_list'].length - 1;
              protocol_data[i] = protocol_data.param_list[i]['text_list'][last_ind].txt;
            } else {
              if (!protocol_data[i]) {
                protocol_data[i] = '';
              }
            }
          }
        }
      }
    },
    f_saveWdmFromHimsScreen: function () {
      let send_status = false; // en az bir tane gönderilecek protokol var mı bakıyoruz.
      let all_alert_text = '';
      let all_user_will_fill = '';
      if (Object.keys(this.hims_protocol_list).length > 0) {
        for (let protocol_no in this.hims_protocol_list) {
          if (this.hims_protocol_list[protocol_no].send_status) {
            send_status = true;
            this.f_protocolManuelEditControl(this.hims_protocol_list[protocol_no]);
            let alert_text = '';
            let user_will_fill = '';
            for (let p in this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order']) {
              let param = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order'][p];
              let param_data = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param];
              if (param_data['required'] && !this.hims_protocol_list[protocol_no][param]) {
                if (param_data.default === 'user_will_fill') {
                  user_will_fill += param_data.name.label + '\n';
                } else {
                  if (param_data.default === 'today') {
                    let today = new Date();
                    alert_text += param_data.name.label + ' > "' + moment(today).toISOString() + '"\n';
                  } else {
                    alert_text += param_data.name.label + ' > "' + param_data.default+'"\n';
                  }
                }
              }
            }
            if (user_will_fill) {
              user_will_fill = protocol_no + ' protokol nolu muayene bilgilerinde kayıtlı zorunlu alanları doldurunuz. Aşağıdaki alanları lütfen doldurunuz.\n' + user_will_fill;
            }
            all_user_will_fill += user_will_fill;
            if (alert_text) {
              alert_text = protocol_no +
                ' protokol nolu muayene bilgileri kaydında zorunlu alanları doldurunuz. Aşağıdaki hazırlanan şekliyle onaylamak istiyorsanız onaylayarak devam edebilirsiniz.\n' +
                alert_text;
            }
            all_alert_text += alert_text;
          }
        }
      }
      if (send_status) {
        if (!all_alert_text && !all_user_will_fill) {
          this.d_showHimsProtocols = false;
          this.f_saveWdmContinue();
        } else {
          if (all_user_will_fill) {
            let modal_data = { 'type': 'alert', 'text': all_user_will_fill, 'centered': true, 'title': 'HBYS Fatura Muayene Bilgileri Kaydı Hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          } else if (all_alert_text) {
            let modal_data = { 'type': 'confirm', 'text': all_alert_text, 'centered': true, 'title': 'HBYS Fatura Muayene Bilgileri Kaydı Hakkında', 'function_name': 'f_addDefaultTextToHimsInPatientWdmr', 'confirm': false, 'arguments': [] };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        }
      } else {
        let modal_data = { 'type': 'alert', 'text': 'HBYS muayene veri kaydı yapılmadı', 'centered': true, 'title': 'HBYS Fatura Muayene Bilgileri Kaydı Hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        this.d_showHimsProtocols = false;
        this.f_saveWdmContinue();
      }
    },
    f_addDefaultTextToHimsInPatientWdmr: function () {
      for (let protocol_no in this.hims_protocol_list) {
        if (this.hims_protocol_list[protocol_no].send_status) {
          for (let p in this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order']) {
            let param = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order'][p];
            let param_data = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param];
            if (param_data['required'] && !this.hims_protocol_list[protocol_no][param] && param_data.default !== 'user_will_fill') {
              if (param_data.default === 'today') {
                let today = new Date();
                this.hims_protocol_list[protocol_no][param] = moment(today).toISOString();
              } else {
                this.hims_protocol_list[protocol_no][param] = param_data.default;
              }
            }
          }
        }
      }
      this.d_showHimsProtocols = false;
      this.f_saveWdmContinue();
    },
    f_saveWdmContinue: function (edit_in_the_location_index = '') {
      let editing_index = this.edit_index;
      if (edit_in_the_location_index !== '') {
        editing_index = edit_in_the_location_index;
      }
      // console.log('f_saveWdmContinue:::', this.wisdom_data);
      // this.$refs.wisdom_data_modal.calculate_name();
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['require', 'name']);
      // console.log('return_data: ', return_data);
      // return;
      let wdmr_name = return_data.name;
      this.wisdom_data['label'] = wdmr_name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      // Bir wdmr kaydı yapabilmek için en az bir parametre dolu olması gerekiyor.
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      // wdmr kaydı sırasında tüm wdm parametreleri için require kontrolü yapıyor. İlgili parametrede val değeri oluştuysa zaten oraya girilmiş oluyor. İçine girilipte (wisdom-param componenti) dolu olmayan tüm parametreleri kontrol ediyoruz. Örneğin er ihd testini ekledi. Fakat pozitif / negatif değerini doldurmadıysa geçemiyor.
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['require', 'name', 'delete']);
      this.wisdom_data = JSON.parse(JSON.stringify(return_data.wdmr));
      this.wisdom_data['label'] = wdmr_name;
      this.show_new_wisdom_data = false;
      this.d_showWdmModal = false;
      let d_uuid = this.wisdom_data.id ? this.wisdom_data.id : '';
      let data = {
        'webservice': {
          'hims_protocol_list': this.hims_protocol_list,
          'hospitalGroup': this.patient.hospital_group,
          'hospitalCode': this.patient.hospital_id
        },
        'data': this.wisdom_data
      };
      WisdomDataService.save_wdm(this.patient.id, d_uuid, data).then(resp => {
        // console.log(resp.data);
        if (resp.data.status === "success") {
          this.d_fastMode = { 'option_data': {}, 'data_type': '', 'wisdom_data': {} };
          this.edit_index = '';
          this.d_editInThisLocation = false;
          // console.log(resp.data.result);
          let result_wdmr = resp.data.result;
          // console.log(result_wdmr);
          let key = result_wdmr.reference_wdm.key;
          if (!this.patient_wdm_list[key]) {
            this.patient_wdm_list[key] = this.option_data[this.data_type];
          }
          // console.log(editing_index);
          if (this.wisdom_op === "edit") {
            let total_length = this.patient_data_list.length;
            if (this.d_dateListMode === 'first_new') {
              this.patient_data_list.splice(total_length - parseInt(editing_index) - 1, 1);
            } else {
              this.patient_data_list.splice(editing_index, 1);
            }
          }
          let det = false;
          for (let i in this.patient_data_list) {
            // if (this.patient_data_list[i].type === result_wdmr.type && this.patient_data_list[i].id === result_wdmr.id) {
            //   this.patient_data_list.splice(i, 0, result_wdmr);
            //   det = true;
            //   break;
            // }
            if (DateFunctions.datetime_dif(this.patient_data_list[i].date, result_wdmr.date) <= 0) {
              this.patient_data_list.splice(i, 0, result_wdmr);
              det = true;
              break;
            }
          }
          if (!det) {
            this.patient_data_list.push(result_wdmr);
          }
          this.wdmr_add_edit_change();
          this.wisdom_show_update();
          if (this.d_selectedWdmList.indexOf(this.wisdom_data.type) === -1) {
            this.d_selectedWdmList.push(this.wisdom_data.type);
          }
          this.f_updateLastXWdmList();
          if (this.d_timeLineMode && this.d_selectedWdmList.indexOf(this.wisdom_data.type) !== -1) {
            this.f_getPatientRecords();
          }
          if (result_wdmr.type === 'wdm10' && this.d_drugMode) {
            this.d_patientDrugEditData.status = 0;
            if (this.d_wdmr10ShowMode) {
              this.f_getPatientRecords();
            } else {
              this.f_getWdmr11orWdmr12List();
            }
          }
          let modal_data = { 'type': 'alert', 'title': 'Veri modeli kayıt işlemi hakkında', 'text': 'Veri modeli kayıt işlemi başarıyla tamamlandı.' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        } else {
          alert('error: ', resp.data.message);
        }
      });
    },
    f_turnWisdomDataShowMode: function (resp_wdmr_list) {
      // resp_wdmr_list = {'1': {'wisdom_data': {}, 'save_ability': 1}, ....}
      this.d_multiWdmrMode = 0;
      // We are adding wdm type list to view on view mode.
      for (let i in this.d_multiWdmrData.multi_wdmr_list) {
        if (this.d_multiWdmrData.multi_wdmr_list[i].save_ability) {
          let d_type = this.d_multiWdmrData.multi_wdmr_list[i].data_type;
          if (this.d_selectedWdmList.indexOf(d_type) === -1) {
            this.d_selectedWdmList.push(d_type);
          }
          if (!this.patient_wdm_list[this.d_multiWdmrData.multi_wdmr_list[i].wisdom_data.reference_wdm.key]) {
            this.patient_wdm_list[this.d_multiWdmrData.multi_wdmr_list[i].wisdom_data.reference_wdm.key] = this.d_multiWdmrData.multi_wdmr_list[i].option_data[d_type];
          }
        }
      }
      this.f_updateLastXWdmList();
      for (let i in resp_wdmr_list) {
        let result_wdmr = resp_wdmr_list[i].wisdom_data;
        let det = false;
        for (let i in this.patient_data_list) {
          if (DateFunctions.datetime_dif(this.patient_data_list[i].date, result_wdmr.date) <= 0) {
            this.patient_data_list.splice(i, 0, result_wdmr);
            det = true;
            break;
          }
        }
        if (!det) {
          this.patient_data_list.push(result_wdmr);
        }
      }
      this.d_multiWdmrData.multi_wdmr_list = [];
    },
    f_updateLastXWdmList: function () {
      let new_list = {};
      for (let i in this.d_selectedWdmList) {
        if (this.d_searchData.show_last_x_data_list[this.d_selectedWdmList[i]]) {
          new_list[this.d_selectedWdmList[i]] = this.d_searchData.show_last_x_data_list[this.d_selectedWdmList[i]];
        } else {
          new_list[this.d_selectedWdmList[i]] = { 'val': 1 };
        }
      }
      this.d_searchData.show_last_x_data_list = new_list;
    },
    f_updateLastXWdmList: function () {
      let new_list = {};
      for (let i in this.d_selectedWdmList) {
        if (this.d_searchData.show_last_x_data_list[this.d_selectedWdmList[i]]) {
          new_list[this.d_selectedWdmList[i]] = this.d_searchData.show_last_x_data_list[this.d_selectedWdmList[i]];
        } else {
          new_list[this.d_selectedWdmList[i]] = { 'val': 1 };
        }
      }
      this.d_searchData.show_last_x_data_list = new_list;
    },
    wdmr_add_edit_change: function () {
      if (this.wdmr_add_edit !== undefined) {
        this.wdmr_add_edit.change === 0 ? this.wdmr_add_edit.change = 1 : this.wdmr_add_edit.change = 0;
      }
    },
    add_new_wisdom_data: function (data_type, type = 'with_modal') {
      // console.log(data_type);
      if (this.d_multiWdmrMode) {
        type = 'multi_wdmr';
      }
      if (type === 'fast_mode') {
        if (this.d_fastMode.data_type === data_type) {
          this.d_fastMode.option_data = {};
          this.d_fastMode.wisdom_data = {};
          this.d_fastMode.data_type = '';
          this.d_showFastWdmrAddMode = 0;
          return;
        }
      } else if (type === 'multi_wdmr') {}
      this.wisdom_op = "new";
      this.data_type = data_type;
      // predata hazırlanma sebebi bir değişkene eşittir diğer değişken dendiğinde computed property değişiyor. Fakat datanın içerisindeki sadece bir bilgi değişince sayfada re render olmuyor. dolayısıyla önce predata hazırlayıp. asıl datamıza yani wisdom data ya eşitliyoruz aşağıda.
      let pre_data = {};
      let data_record_count_type = '';
      for (let i in this.wdm.ful.patient_follow) {
        if (this.wdm.ful.patient_follow[i].value === data_type) {
          data_record_count_type = this.wdm.ful.patient_follow[i].record_count;
          break;
        }
      }
      if (data_record_count_type === 'many_times') {
        pre_data = { 'data': {}, 'date': '', 'type': data_type, 'reference_wdm': {} };
      } else {
        pre_data = { 'data': {}, 'type': data_type, 'reference_wdm': {} };
      }
      let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
      let doc_key = '';
      let doc_version = '';
      let bucket = '';
      let data_type_label = '';
      if (wdm_version.wisdom.all[data_type]) {
        data_type_label = wdm_version.wisdom.all[data_type].name.label;
      }
      if (wdm_version.user.all[data_type]) {
        data_type_label = wdm_version.user.all[data_type].name.label;
      }
      if (wdm_version.hospital.all[data_type]) {
        data_type_label = wdm_version.hospital.all[data_type].name.label;
      }
      if (wdm_version.wisdom.last[data_type]) {
        doc_key = wdm_version.wisdom.last[data_type].key;
        doc_version = wdm_version.wisdom.last[data_type].version;
        bucket = 'option';
      }
      if (wdm_version.user.last[data_type]) {
        doc_key = wdm_version.user.last[data_type].key;
        doc_version = wdm_version.user.last[data_type].version;
        bucket = 'hospital';
      }
      // If data_type defined in also hospital it will be selected. Then this below code added to future.
      if (wdm_version.hospital.last[data_type]) {
        doc_key = wdm_version.hospital.last[data_type].key;
        doc_version = wdm_version.hospital.last[data_type].version;
        bucket = 'hospital';
      }
      let data = {
        'wdm_list': [{
          'value': doc_key,
          'bucket': bucket
        }],
        'target_lang': ''
      };
      WdmService.get_wdm_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            let option_data = resp.data.result.wdm_list[0];
            for (let i in option_data.param_group.param_order) {
              pre_data['data'][option_data.param_group.param_order[i]] = {};
            }
            pre_data['reference_wdm']['version'] = doc_version;
            pre_data['reference_wdm']['owner_type'] = option_data['owner_type'];
            pre_data['reference_wdm']['key'] = doc_key;
            if (type === 'fast_mode') {
              this.d_fastMode.option_data = {};
              this.d_fastMode.option_data[data_type] = option_data;
              this.d_fastMode.wisdom_data = pre_data;
              this.d_fastMode.data_type = data_type;
            } else if (type === 'with_modal') {
              this.option_data = {};
              this.option_data[data_type] = option_data;
              this.wisdom_data = pre_data;
            } else if (type === 'multi_wdmr') {
              let multi_wdmr_data = {
                'option_data': {},
                'data_type': data_type,
                'data_type_label': data_type_label,
                'wisdom_data': pre_data,
                'dr_wisdom': '',
                'schema_name': '',
                'schema_key': ''
              };
              multi_wdmr_data.option_data[data_type] = option_data;
              this.d_multiWdmrData.multi_wdmr_list.push(multi_wdmr_data);
            }
            if (type === 'with_modal') {
              this.d_showWdmModal = true;
            } else if (type === 'fast_mode') {
              this.d_showFastWdmrAddMode = 1;
            } else if (type === 'multi_wdmr') {
              //
            }
            this.$forceUpdate();
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    DateFormat: function (date) {
      if (date) {
        if (date.indexOf('T') === -1) {
          return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
        } else {
          return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD.MM.YYYY HH:mm");
        }
      } else {
        return "";
      }
    }
  },
  watch: {
    'd_drugModeDepartmentFilterType': function () {
      this.f_getWdmr11orWdmr12List();
    },
    'd_multiWdmrMode': function () {
      if (!this.d_multiWdmrMode) {
        this.d_selectedMultiWdmrIndex = '';
      }
    },
    'mother_children.forceUpdate': function () {
      this.$forceUpdate();
    },
    'mother_children.get_patient_records': function () {
      if (this.d_drugMode) {
        if (this.d_wdmr10ShowMode) {
          this.f_getPatientRecords();
        } else {
          this.f_getWdmr11orWdmr12List();
        }
      } else {
        this.f_getPatientRecords();
      }
      if (this.d_showDrugModeTimeLine) {
        this.d_showDrugModeTimeLine = false;
      }
    },
    'mother_children.change_status': function () {
      if (this.mother_children.wdmr10_data) {
        this.patient_data_list[this.d_selectedWdmr10.index].data.status = { 'status': this.mother_children.wdmr10_data.status };
        this.f_motherChildren('update_patient_wdmr');
        this.$forceUpdate();
      }
    },
    'd_patientDrugEditData.status': function () {
      if (this.d_patientDrugEditData.status) {
        this.wisdom_op = "edit";
        this.data_type = this.d_patientDrugEditData.data_type;
        GlobalService.get_one_cb_document({ 'key': this.d_patientDrugEditData.key, 'bucket': 'wisdom' })
          .then(resp => {
            if (resp.data.status === 'success') {
              this.wisdom_data = resp.data.result;
              let key = this.wisdom_data.reference_wdm.key;
              this.option_data = {};
              this.option_data[this.d_patientDrugEditData.data_type] = this.patient_wdm_list[key];
              this.d_showWdmModal = true;
            } else {
              alert('error ', resp.data.message);
            }
          });
      }
    },
    'patient_data_list': function () {
      this.reverse_patient_data_list = JSON.parse(JSON.stringify(this.patient_data_list.slice().reverse()));
      this.$forceUpdate();
    },
    'd_selectedWdmList': function () {
      this.f_updateLastXWdmList();
    },
    'd_showScreenSettings': function () {
      if (this.d_showScreenSettings) {
        this.patient_data_list = [];
        this.option_data = {};
        this.f_myBriefWdmParameterListSettings();
      }
    },
    'd_wdmParameters.selected_wdm': function () {
      if (this.d_wdmParameters.selected_wdm) {
        // console.log(this.d_wdmParameters.selected_wdm);
        if (this.d_wdmParameters.selected_wdm.brief_list) {
          this.d_wdmParameters.brief_list[this.d_wdmParameters.selected_wdm.value] = this.d_wdmParameters.selected_wdm.brief_list;
        } else {
          this.d_wdmParameters.brief_list[this.d_wdmParameters.selected_wdm.value] = [];
        }
        let data_type = this.d_wdmParameters.selected_wdm.wdm_type;
        let buck = '';
        this.d_wdmParameters.data_type = '';
        if (this.d_wdmParameters.selected_wdm.location_type === 'wisdom') {
          buck = 'option';
        } else {
          buck = 'hospital';
        }
        let doc_key = this.d_wdmParameters.selected_wdm.value;
        let query = 'data_type=' + data_type + '&location=' + buck;
        GlobalService.get_one_cb_document({ 'key': doc_key, 'bucket': buck })
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_wdmParameters.option_data = {};
              this.d_wdmParameters.option_data[data_type] = resp.data.result;
              this.d_wdmParameters.data_type = data_type;
              this.$forceUpdate();
            } else {
              alert('error ', resp.data.message);
            }
          });
      }
    },
    'd_dateListMode': function () {
      this.f_showTrueFalse();
    },
    'wdm.status': function () {
      if (this.wdm.ful && this.wdm.ful.patient_follow) {
        this.d_selectedWdmList = [];
        for (let i in this.wdm.ful.patient_follow) {
          if (this.d_selectedWdmList.indexOf(this.wdm.ful.patient_follow[i].value) === -1) {
            this.d_selectedWdmList.push(this.wdm.ful.patient_follow[i].value);
          }
        }
      }
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deleteWdmrComplete' && this.StoreModal.data.confirm) {
          this.f_deleteWdmrComplete(this.StoreModal.data.arguments);
        } else if (this.StoreModal.data.function_name === 'f_addDefaultTextToHimsInPatientWdmr' && this.StoreModal.data.confirm) {
          this.f_addDefaultTextToHimsInPatientWdmr();
        } else if (this.StoreModal.data.function_name === 'f_continueWdm11Confirmed' && this.StoreModal.data.confirm) {
          this.f_continueWdm11Confirmed(this.StoreModal.data.arguments);
        } else if (this.StoreModal.data.function_name === 'f_removeApproveWdm11Confirmed' && this.StoreModal.data.confirm) {
          this.f_removeApproveWdm11Confirmed(this.StoreModal.data.arguments);
        }
      }
    }
  },
  components: {
    BmiGfrWidget,
    Datepicker,
    HimsProtocolDetails,
    Modal,
    PatientDrug,
    PatientOrderWidget,
    PatientReportInformation,
    TimeLine,
    TreeList,
    vSelect,
    WdmParameters,
    WdmrReport,
    WdmrSchemaMulti,
    WisdomDataModal,
    WisdomDataShow,
    MultiWdmr
  }
};

</script>

<style type="text/css">
/*  .v-select.searchable .dropdown-toggle {
    height: 25px;
  } */


/*  .v-select .selected-tag {
    margin: 0px;
    padding: 0px;
  } */

.normal-mode {}

.full-screen-mode {
  margin: 0px;
  background-color: white;
  height: 500px;
  overflow-y: auto;
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.specific-wisdom-data {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 500px;
  max-width: 100%;
}

.small-wisdom-data {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 150px;
}

.long-wisdom-data {}

#wisdom-data::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#wisdom-data::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

#wisdom-data::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #20a8d8;
}

#s_operationsScrollbar::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0;
  background-color: #d9e9ea;
}

#s_operationsScrollbar::-webkit-scrollbar {
  width: 10px;
  /*background-color: #F5F5F5;*/
}

#s_operationsScrollbar::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.s_rowSelected {
  margin: 0px;
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowSelected:hover {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowNotSelected {
  margin: 0px;
  background-color: white;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowNotSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
  /*border-bottom: solid 1px #e6e6e6;*/
}

</style>

